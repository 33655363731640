/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { BoxProps, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { CheckIcon, CloseIcon, Icon } from '@chakra-ui/icons'
import { DownloadIcon } from './DownloadIcon'
import Dragger from 'antd/es/upload/Dragger'
import { UploadFile } from 'antd/es'
import { RcFile, UploadChangeParam } from 'antd/es/upload'

export type CsvFileUploadProps = BoxProps & {
  maxCsvFileWeightInMb: number
  csvChooseFileLabel: string
  csvFileName: string | null
  csvError: () => string | undefined
  csvFileList: UploadFile<any>[]
  handleCsvFormatValidation?: (file: RcFile, FileList: RcFile[]) => boolean | Promise<boolean>
  handleCsvPreview?: (file: UploadFile<any>) => void
  handleCsvFileChange?: (info: UploadChangeParam<UploadFile<any>>) => void
  setIsLoading: (loading: boolean) => void
  setIsSuccess: (success: boolean) => void
}

export function CsvFileUpload(props: CsvFileUploadProps): React.JSX.Element {
  const importControl = (
    <Flex
      fontSize='20px'
      fontFamily='Nunito Sans'
      fontWeight='600'
      lineHeight='125%'
      color='#6AA5FD'
      padding='20px 32px'
      border={'1px solid #6AA5FD'}
      borderRadius='8px'
      backdropFilter='blur(15px)'
      gap='12px'
    >
      <FormLabel margin='0 auto' fontSize='lg'>
        {props.csvChooseFileLabel}
        <Icon as={DownloadIcon()} ml='12px' alignSelf='flex-end' />
      </FormLabel>
    </Flex>
  )

  const hasCsvFileName = (props.csvFileName?.length ?? 0) > 0
  const statusMark =
    props.csvError() === undefined && hasCsvFileName ? (
      <CheckIcon color={'green.700'} />
    ) : props.csvError() !== undefined && hasCsvFileName ? (
      <CloseIcon color='red.500' />
    ) : null

  const csvWithContent = (
    <Flex
      padding='20px 32px'
      w='full'
      justifyContent='center'
      alignItems='center'
      gap='12px'
      alignSelf='stretch'
      borderRadius='8px'
      border={
        props.csvError() === undefined && hasCsvFileName
          ? '1px solid #2E7B72'
          : '1px solid var(--chakra-colors-red-500)'
      }
      backdropFilter='blur(15px)'
      color={props.csvError() === undefined && hasCsvFileName ? 'green.700' : 'red.500'}
    >
      {props.csvFileName}
      {statusMark}
    </Flex>
  )

  return (
    <FormControl isInvalid={props.csvError() !== undefined} mb='40px' alignSelf='center' w='544px'>
      <Dragger
        listType='picture-card'
        fileList={props.csvFileList}
        beforeUpload={props.handleCsvFormatValidation}
        onPreview={props.handleCsvPreview}
        onChange={props.handleCsvFileChange}
        showUploadList={false}
        maxCount={1}
        className={props.className}
        data-testid={'upload'}
      >
        {props.csvFileName !== null ? csvWithContent : importControl}
      </Dragger>
      {props.csvError() !== undefined ? (
        <FormErrorMessage mt='24px'>{props.csvError()} </FormErrorMessage>
      ) : null}
    </FormControl>
  )
}
