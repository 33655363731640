import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { fetchApi, fetchFormEncoded } from './storeHelper'
import Gift, { GiftType, OpeningMode } from '../domain/gift'

import producesInMemoryProjectStore from './inMemory/inMemoryProjectStore'
import { Receiver } from '../domain/receiver'
import { Template } from '../domain/template'
import OpeningGame from '../domain/openingGame'
import ProjectClient from '../domain/adapters/secondary/projectClient'
import { createProjectSlice } from './slices/project/projectSlice'
import GiftClient from '../domain/adapters/secondary/giftClient'
import { createGiftSlice } from './slices/gift/giftSlice'
import OccasionClient from '../domain/adapters/secondary/occasionClient'
import { createOccasionSlice } from './slices/occasion/occasionSlice'
import TemplateClient from '../domain/adapters/secondary/templateClient'
import { createTemplateSlice } from './slices/template/templateSlice'
import LayoutClient from '../domain/adapters/secondary/layoutClient'
import { createLayoutSlice } from './slices/layout/layoutSlice'
import AnswerClient from '../domain/adapters/secondary/answerClient'
import { createAnswerSlice } from './slices/answer/answerSlice'
import ClientClient from '../domain/adapters/secondary/clientClient'
import { createClientSlice } from './slices/client/clientSlice'
import CommandClient from '../domain/adapters/secondary/commandClient'
import { createCommandSlice } from './slices/command/commandSlice'

export type Opening = {
  openingMode: OpeningMode
  openingDate?: string
  openingGame?: OpeningGame
}

export type GiftInfos = Pick<Gift, 'id' | 'title' | 'description' | 'link' | 'type'> & {
  picture: Blob | undefined
  pdf: Blob | undefined
  pdfFileName: string | undefined
  pictureFileName: string | undefined
}

export type GiftEventInfos = {
  giftId: number
  occasion?: string
  category?: string
  date?: Date
  receivers: Receiver[] | undefined
  sendingMode: 'UNIQUE' | 'MULTIPLE' | undefined
}

export interface ProjectStoreState
  extends ProjectClient,
    GiftClient,
    OccasionClient,
    TemplateClient,
    LayoutClient,
    AnswerClient,
    ClientClient,
    CommandClient {
  updateGiftInfos: (projectId: number, gift: GiftInfos) => Promise<Gift>
  updateGiftOpeningMode: (
    projectId: number,
    giftId: number,
    openingMode: OpeningMode,
    openingDate?: Date,
    openingGame?: OpeningGame
  ) => Promise<Opening>
  updateGiftTemplate: (projectId: number, giftId: number, templateId: number) => Promise<Template>
}

const producesUseProjectStore = () => {
  return create<ProjectStoreState>()(
    immer<ProjectStoreState>((set, get, store) => {
      return {
        ...createProjectSlice(set, get, store),
        ...createGiftSlice(set, get, store),
        ...createOccasionSlice(set, get, store),
        ...createTemplateSlice(set, get, store),
        ...createLayoutSlice(set, get, store),
        ...createAnswerSlice(set, get, store),
        ...createClientSlice(set, get, store),
        ...createCommandSlice(set, get, store),
        updateGiftInfos: async (projectId: number, gift: GiftInfos) => {
          type UpdateGiftBody = {
            title?: string
            description?: string
            picture?: Blob
            link?: string
            type: GiftType
            pdf?: Blob
          }
          const makeBody = (gift: GiftInfos): UpdateGiftBody => {
            return Object.assign(
              {
                title: gift.title,
                description: gift.description,
                type: gift.type,
                picture: gift.picture,
                pictureFileName: gift.pictureFileName,
                pdfFileName: gift.pdfFileName
              },
              gift.type === 'PDF'
                ? {
                    pdf: gift.pdf
                  }
                : gift.type === 'LINK'
                ? { link: gift.link }
                : {}
            )
          }

          const updatedGift = await fetchFormEncoded<Gift, UpdateGiftBody>(
            `projects/${projectId}/gifts/${gift.id}`,
            'PUT',
            makeBody(gift)
          )
          set(state => {
            if (gift && gift.id && gift.pictureFileName) {
              state.giftPictureFileNameById[gift.id] = gift.pictureFileName
            }
            const projIdx = state.projects.findIndex(proj => proj.id === projectId)
            const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === gift.id)
            state.projects[projIdx].gifts[giftIdx] = updatedGift
          })
          return updatedGift
        },
        updateGiftTemplate: async (projectId: number, giftId: number, templateId: number) => {
          const updateTemplateBody = { templateId }
          const choosenTemplate = await fetchApi<Template>(
            `projects/${projectId}/gifts/${giftId}/template`,
            'PUT',
            JSON.stringify(updateTemplateBody)
          )
          set(state => {
            const projectIdx = state.projects.findIndex(proj => proj.id === projectId)
            const giftIdx = state.projects[projectIdx].gifts.findIndex(g => g.id === giftId)
            state.projects[projectIdx].gifts[giftIdx].template = choosenTemplate
          })
          return choosenTemplate
        }
      }
    })
  )
}

const projectStore =
  process.env.REACT_APP_STORE_MODE === 'IN_MEMORY'
    ? producesInMemoryProjectStore()
    : producesUseProjectStore()

export default projectStore
