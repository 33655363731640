import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useProjectStore from '../stores/projectStore'

import './ExperienceSlide2.css'

export default function Slide2() {
  const giftClient = useProjectStore(SlicesHelper.getGiftClient)

  const layoutMessage = giftClient.openedGift?.template?.layouts.find(
    layout => layout.role === 'MESSAGE'
  )
  const hasLayoutMessageSecondImage =
    layoutMessage?.pictures?.find(p => p.position === 2 && (p.path?.length ?? 0) > 0) !== undefined

  return (
    <main
      className={'container-2'}
      style={{
        backgroundColor: giftClient.openedGift?.template?.solidBackground2
      }}
    >
      <h1>{layoutMessage?.texts[0]?.text}</h1>
      <div className={'img-container'}>
        {hasLayoutMessageSecondImage && (
          <img
            alt={'Cadeau'}
            src={`${process.env.REACT_APP_API_URL}/${layoutMessage?.pictures?.find(
              p => p.position === 2
            )?.path}`}
          />
        )}
      </div>
    </main>
  )
}
