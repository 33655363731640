import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import SmallText from '../components/SmallText'
import { getSteps } from './breadcrumbStepsHelper'
import Page from '../components/Page'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import useProjectStore from '../stores/projectStore'
import { Card, Flex } from '@chakra-ui/react'
import { Calendar } from 'react-calendar'

import './EventDate.css'
import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useChangeGiftEventDateUseCase from '../useCases/changeGiftEventDateUseCase'
import { View } from 'react-calendar/dist/esm/shared/types'

export default function EventDate(): React.JSX.Element {
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const {
    // event date
    hasDateChanged,
    activeStartDate,
    updateError,
    date,

    currentStoreGiftHasDate,
    activeStartDateChanged,
    dateChanged,
    updateDate,
    shouldDisable,
    dateStatus,
    // format
    formatWeekday,
    // navigation
    currentProjectId,
    currentStoreGift
  } = useChangeGiftEventDateUseCase(giftClient)

  const occasionClient = useProjectStore(SlicesHelper.getOccasionSlice)

  const steps: Step[] = getSteps('Évènement', currentStoreGift?.reachedStep)
  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='eventDateTitleLabel'
          defaultMessage='Quand à lieu cet évènement ?'
        />
      </Header2>
      <SmallText mb='24px'>
        <FormattedMessage
          description='eventDateSubtitle'
          defaultMessage='Sélectionnez le jour où vous comptez offrir ce cadeau en cliquant sur le calendrier.'
        />
      </SmallText>

      <Card
        w='554px'
        alignSelf='center'
        className='calendar-card'
        backgroundColor='transparent'
        shadow='none'
        border='0px'
        _hover={{
          backgroundColor: 'transparent',
          shadow: 'none',
          border: '0px'
        }}
      >
        <Calendar
          onChange={value => dateChanged(value as unknown as string)}
          value={date}
          view='month'
          tileClassName={({
            date: tileDate,
            view
          }: {
            activeStartDate: Date
            date: Date
            view: View
          }) => {
            if (view !== 'month') {
              return
            }
            const status = dateStatus(tileDate, date, activeStartDate)

            type dateStatuses = 'disabled' | 'today' | 'selectedDay' | 'notInStartingMonth'

            const classSelector: Record<dateStatuses, string> = {
              disabled: 'calendar-tile-disabled',
              today: 'calendar-tile-today',
              selectedDay: 'calendar-tile-selected',
              notInStartingMonth: 'calendar-tile-diffMonth'
            }
            const availableStatuses = [
              'disabled',
              'today',
              'selectedDay',
              'notInStartingMonth'
            ] as dateStatuses[]

            return `calendar-tile ${(status !== undefined
              ? availableStatuses.map(state =>
                  status.includes(state) ? classSelector[state] : undefined
                )
              : ['']
            ).join(' ')}`
          }}
          tileDisabled={({ date: tileDate }) => shouldDisable(tileDate)}
          formatShortWeekday={formatWeekday}
          onActiveStartDateChange={({ activeStartDate }) => activeStartDateChanged(activeStartDate)}
          nextLabel={
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.70504 6.5L8.29504 7.91L12.875 12.5L8.29504 17.09L9.70504 18.5L15.705 12.5L9.70504 6.5Z'
                fill='#49454F'
              />
            </svg>
          }
          prevLabel={
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.705 7.91L14.295 6.5L8.29504 12.5L14.295 18.5L15.705 17.09L11.125 12.5L15.705 7.91Z'
                fill='#49454F'
              />
            </svg>
          }
        />
      </Card>
      {updateError && <Flex color='red.500'>{updateError}</Flex>}
      {currentStoreGift && (
        <Flex w='full' justifyContent='center' gap='24px' mt='80px' mb='24px'>
          <SecondaryButton
            as={DomLink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              const isCustomOccasion = !Object.values(
                occasionClient.occasionsByCategory.occasions.categories
              )
                .flatMap(occasions => occasions)
                .includes(currentStoreGift?.event?.occasion ?? '')
              navigate(
                `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/${
                  isCustomOccasion ? 'otherOccasion' : 'occasion'
                }`
              )
            }}
          >
            <FormattedMessage description='generalReturn' defaultMessage='Retour' />
          </SecondaryButton>
          <PrimaryButton
            isDisabled={(!hasDateChanged && !currentStoreGiftHasDate) || updateError !== null}
            className={
              (!hasDateChanged && !currentStoreGiftHasDate) || updateError != null
                ? 'disabled-primary-btn'
                : ''
            }
            onClick={async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
              e.preventDefault()
              const success = await updateDate()
              if (!success) {
                return
              }
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/template`)
            }}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      )}
    </Page>
  )
}
