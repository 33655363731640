import { UploadFile, UploadProps } from 'antd'
import { useCallback, useReducer } from 'react'
import { File } from '../FileUpload'
import { RcFile } from 'antd/es/upload'
import { v4 as uuid } from 'uuid'

export type FileProps = {
  fileList: UploadFile<File>[]
  fileName: string | null
  fileContent: string | null
  uploadError: string | null
}

export type FileAction = {
  type: 'UPLOAD_FILE' | 'ERROR' | 'CLEAR_ERROR'
  payload: { file: File | null; error: string | null }
}

type FileReducer = (props: FileProps, action: FileAction) => FileProps

export type UseFileUploadReducerProps = {
  initialFilePath: string | null
  validMimeTypes: string[]
  maxWeightInMB: number
  onUploadError: (err: string, fileName: string | null) => void
  onUpload: (file: UploadFile<File>) => void
}

export function useFileUploadReducer(props: UseFileUploadReducerProps) {
  const uploadAction = (actionProps: FileProps, action: FileAction) =>
    Object.assign({}, props, {
      fileList: [...actionProps.fileList, action.payload],
      fileName: action.payload.file?.name,
      fileContent: action.payload.file?.previewUrl,
      uploadError: null
    }) as FileProps

  const errorAction = (actionProps: FileProps, action: FileAction) => {
    props.onUploadError(action.payload.error || '', action.payload.file?.name ?? null)
    return {
      fileList: [],
      fileName: action.payload.file?.name || '',
      fileContent: null,
      uploadError: action.payload.error
    }
  }

  const clearError = (actionProps: FileProps) =>
    Object.assign({}, actionProps, { uploadError: null })

  const actionSelector: Record<'UPLOAD_FILE' | 'ERROR' | 'CLEAR_ERROR', FileReducer> = {
    UPLOAD_FILE: uploadAction,
    ERROR: errorAction,
    CLEAR_ERROR: clearError
  }

  const fileUploadReducer: FileReducer = (props, action) =>
    actionSelector[action.type](props, action)

  const [state, dispatch] = useReducer(fileUploadReducer, {
    fileList: [],
    fileName: props.initialFilePath,
    fileContent: null,
    uploadError: null
  })

  const getBase64 = useCallback(async (blob: Blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    const prom = await new Promise<string | ArrayBuffer>((res, rej) => {
      reader.addEventListener('load', () => res(reader.result || ''))
      reader.addEventListener('error', () => rej(reader.error))
    })

    if (prom instanceof ArrayBuffer) {
      const dec = new window.TextDecoder('utf-8')
      return dec.decode(prom)
    }

    return prom
  }, [])

  const handlePreview: UploadProps['onPreview'] = useCallback(
    async (file: UploadFile) => {
      if (!file.url && !file.preview && file.originFileObj) {
        file.preview = await getBase64(file.originFileObj.slice())
      }
    },
    [getBase64]
  )

  const beforeUpload = useCallback(
    (file: RcFile) => {
      const isValidImageExt = props.validMimeTypes.includes(file.type)
      const currFile: File = {
        uid: uuid(),
        name: file.name,
        status: 'error',
        preview: null as unknown as Blob,
        url: '',
        previewUrl: ''
      }

      if (!isValidImageExt) {
        dispatch({
          type: 'ERROR',
          payload: { file: currFile, error: 'format error' }
        })
      }

      const isAtOrAboveAllowedSize = file.size / 1024 >= props.maxWeightInMB
      if (isAtOrAboveAllowedSize) {
        dispatch({
          type: 'ERROR',
          payload: { file: currFile, error: 'size error' }
        })
      }
      const isValid = isValidImageExt && !isAtOrAboveAllowedSize

      if (isValid) {
        dispatch({
          type: 'CLEAR_ERROR',
          payload: { file: currFile, error: null }
        })
      }

      return false
    },
    [props.validMimeTypes, props.maxWeightInMB]
  )

  const handleChange: UploadProps['onChange'] = useCallback(
    async ({ file }: { file: UploadFile<File> }) => {
      if (state.uploadError !== null) {
        props.onUploadError(state.uploadError, state.fileName)
        return
      }

      // Get this url from response in real world.
      const currFile = file.originFileObj || (file as RcFile)
      const content = await currFile.arrayBuffer()
      const decoder = new window.TextDecoder('utf-8')
      const blobContent = new Blob([await currFile.slice().arrayBuffer()], {
        type: file.type
      })
      const newFile: UploadFile<File> = {
        uid: file.uid,
        preview: decoder.decode(content),
        url: file.url,
        name: file.name,
        status: 'done',
        response: {
          uid: file.uid,
          url: file.url ?? '',
          name: file.name,
          preview: blobContent,
          previewUrl: await getBase64(blobContent),
          status: 'done'
        }
      }
      if (newFile.response) {
        dispatch({
          type: 'UPLOAD_FILE',
          payload: { file: newFile.response, error: null }
        })
        props.onUpload(newFile)
      }
    },
    [props, state.uploadError, getBase64, state.fileName]
  )

  return {
    state,
    dispatch,
    getBase64,
    handleChange,
    handlePreview,
    beforeUpload
  }
}
