import React, { createContext, useContext, useReducer } from 'react'
import { Picture } from '../../domain/picture'
import { Crop, PixelCrop } from 'react-image-crop'
import { BoxProps } from '@chakra-ui/react'
import { RenderItem } from '../../domain/layout'

export type ImageEditorContext = {
  picture?: Picture
  width?: number
  height?: number
  crop?: Crop
  completedCrop?: PixelCrop
  dataUrl?: string
  initialItem?: RenderItem
  itemIndex?: number
  hasCropContext?: boolean
  isCropPopupOpened: boolean
  onItemValidated?: (newItem: RenderItem) => Promise<void>
  blob?: Blob
  imageMode: 'UPLOAD' | 'CROP'
  isUploadValid: boolean
  isImageCropLoading?: boolean
}

type Actions =
  | 'UPDATE'
  | 'COMPLETE'
  | 'OPEN_CROP_POPUP'
  | 'CLOSE_CROP_POPUP'
  | 'INIT_CROP'
  | 'IMAGE_MODE_CROP'
  | 'VALID_UPLOAD'
  | 'INVALID_UPLOAD'
  | 'START_CROP'
  | 'STOP_CROP'

export type ImageEditorDispatchAction = {
  type: Actions
  payload?: ImageEditorContext
}

export type ImageEditorDispatchContext = {
  (action: ImageEditorDispatchAction): void
}

const imageEditorContext = createContext(null as ImageEditorContext | null)
const imageEditorDispatchContext = createContext(null as ImageEditorDispatchContext | null)

export const initialEditorContext: ImageEditorContext = {
  crop: {} as PixelCrop,
  dataUrl: '',
  picture: {} as Picture,
  hasCropContext: false,
  isCropPopupOpened: false,
  imageMode: 'UPLOAD',
  isUploadValid: false,
  isImageCropLoading: false
}

export function ImageEditorProvider({ children }: BoxProps): React.JSX.Element {
  const [editorContext, dispatch] = useReducer(editorReducer, initialEditorContext)
  return (
    <imageEditorContext.Provider value={editorContext}>
      <imageEditorDispatchContext.Provider value={dispatch}>
        {children}
      </imageEditorDispatchContext.Provider>
    </imageEditorContext.Provider>
  )
}

const freeDataUrl = (prevCtx: ImageEditorContext) => {
  if (prevCtx.dataUrl && (prevCtx.dataUrl?.length ?? 0) > 0) {
    URL.revokeObjectURL(prevCtx.dataUrl)
  }
}

export function editorReducer(
  oldCtx: ImageEditorContext,
  action: ImageEditorDispatchAction
): ImageEditorContext {
  const reducerSelector: Record<Actions, (oldCtx: ImageEditorContext) => ImageEditorContext> = {
    UPDATE: prevCtx => ({
      ...prevCtx,
      crop:
        (action.payload?.crop?.width ?? 0) === (prevCtx.crop?.width ?? 0)
          ? action.payload?.crop
          : prevCtx.crop,
      hasCropContext: false
    }),
    COMPLETE: prevCtx => {
      if (prevCtx.dataUrl !== undefined) {
        freeDataUrl(prevCtx)
      }

      return {
        ...prevCtx,
        completedCrop: action.payload?.completedCrop,
        dataUrl: action.payload?.dataUrl,
        hasCropContext: true
      }
    },
    OPEN_CROP_POPUP: prevCtx => {
      if (prevCtx.dataUrl !== undefined) {
        freeDataUrl(prevCtx)
      }
      return {
        ...initialEditorContext,
        crop: prevCtx.crop ?? ({} as Crop),
        initialItem: action.payload?.initialItem,
        onItemValidated: action.payload?.onItemValidated,
        blob: prevCtx.blob,
        isCropPopupOpened: true,
        hasCropContext: false
      }
    },
    CLOSE_CROP_POPUP: prevCtx => {
      return {
        ...prevCtx,
        isCropPopupOpened: false
      }
    },
    INIT_CROP: prevCtx => ({
      ...prevCtx,
      crop: {
        ...(action.payload?.crop ?? ({} as Crop))
      }
    }),
    IMAGE_MODE_CROP: prevCtx => ({
      ...prevCtx,
      blob: action.payload?.blob,
      isCropPopupOpened: true,
      hasCropContext: false,
      imageMode: 'CROP'
    }),
    VALID_UPLOAD: prevCtx => ({
      ...prevCtx,
      isUploadValid: true
    }),
    INVALID_UPLOAD: prevCtx => ({
      ...prevCtx,
      isUploadValid: false
    }),
    START_CROP: prevCtx => ({
      ...prevCtx,
      isImageCropLoading: true
    }),
    STOP_CROP: prevCtx => ({
      ...prevCtx,
      isImageCropLoading: false
    })
  }

  const invalidAction = !(action.type in reducerSelector)
  if (invalidAction) {
    throw new Error('unkown action type!')
  }
  return reducerSelector[action.type](oldCtx)
}

export function useImageEditor() {
  return useContext(imageEditorContext)
}

export function useImageEditorDispatch() {
  return useContext(imageEditorDispatchContext)
}
