/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { GiftEventInfos, GiftInfos, ProjectStoreState } from '../projectStore'
import fakeProjects, {
  fakeCommands,
  fakeLayoutPacks,
  fakeOccasions,
  fakeTemplates
} from '../../tmpFixtures/homePageProjects'
import Gift, { OpeningMode } from '../../domain/gift'
import Project from '../../domain/project'
import { Receiver } from '../../domain/receiver'
import GiftEvent from '../../domain/giftEvent'
import OccasionsByCategory from '../../models/occasionsByCategory'
import PathHelper from '../../helpers/pathHelper'
import { orderLayoutsByRole } from '../../domain/layout'
import { UpdateLayoutItemCommand } from '../../models/commands/updateLayoutItemCommand'
import OpeningGame from '../../domain/openingGame'
import { Command } from '../../domain/command'

function now() {
  return new Date()
}

export type ProjectWithKey = Project & {
  key: number
}

const projects: Project[] = fakeProjects(now())
const gifts: Gift[] = []
const fakeOccasionsByCategory: OccasionsByCategory = fakeOccasions()
const templates = fakeTemplates(now())
const layoutPacks = fakeLayoutPacks(now())
const commands = fakeCommands(now())

const useInMemoryProjectStore = create<ProjectStoreState>()(
  immer<ProjectStoreState>((set, get) => ({
    currentProjectId: -1,
    currentTemplateId: -1,
    projects: projects,
    layoutPacks: layoutPacks,
    occasionsByCategory: { occasions: { categories: {} } },
    currentGiftReceivers: [],
    giftPictureFileNameById: {},
    templates: [],
    openedGift: undefined,
    currentReceiverTrackingId: undefined,
    openedProject: undefined,
    isOpeningGamePassed: false,
    getAll: () => {
      set(state => {
        state.projects = state.projects?.length > 0 ? state.projects : projects
      })
      return Promise.resolve(get().projects)
    },
    projectSelected: (projectId: number) =>
      set(state => {
        state.currentProjectId = projectId
      }),
    addGift: (projectId: number, gift: Pick<Gift, 'type'>) => {
      const nowDate = now()
      const project = projects.find(proj => proj.id === projectId)
      const hasGift = (gifts?.length ?? 0) > 0
      const newGift: Gift = {
        ...gift,
        project: { ...project, id: projectId },
        id: hasGift ? gifts[gifts?.length - 1]?.id! + 1 : 1,
        createdAt: nowDate.toISOString(),
        updatedAt: nowDate.toISOString(),
        step: 'GIFT_TYPE',
        type: gift.type,
        name: undefined,
        title: undefined,
        description: undefined,
        link: undefined,
        pdf: undefined,
        picturePath: undefined,
        event: undefined,
        openingMode: 'NONE',
        openingGame: undefined,
        visualizationLink: '',
        hasBeenOpened: false
      }
      gifts.push(newGift)

      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        state.projects[projIdx].gifts.push(newGift)
      })

      return Promise.resolve(newGift)
    },
    currentGiftReceiversChanged: (_: number, receivers: Receiver[]) => {
      set(state => {
        state.currentGiftReceivers = receivers
      })
    },
    updateGift: async (projectId: number, giftId: number, gift: Pick<Gift, 'type'>) => {
      const updatedGift: Gift = get()
        .projects.find(proj => proj.id === projectId)
        ?.gifts?.find(gift => gift.id === giftId)!
      const giftIndex = gifts.findIndex(g => g.id === giftId)
      if (giftIndex >= 0) {
        gifts[giftIndex].type = updatedGift.type
      }
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === giftId)
        state.projects[projIdx].gifts[giftIdx].type = gift.type
      })
      return updatedGift
    },
    createProject: (project: Pick<Project, 'name'>) => {
      const nowDate = now()
      const newProject: Project = {
        ...project,
        id: projects.length > 0 ? projects[projects.length - 1].id! + 1 : 1,
        status: 'IN_PROGRESS',
        gifts: [],
        createdAt: nowDate,
        updatedAt: nowDate,
        creator: {}
      }

      set(state => {
        state.projects.push(newProject)
      })

      return Promise.resolve(newProject)
    },
    updateGiftInfos: (projectId: number, gift: GiftInfos) => {
      const originalGift = get()
        .projects.find(proj => proj.id === projectId)
        ?.gifts.find(g => g.id === gift.id)
      const updatedGift = Object.assign({}, originalGift!, {
        description: gift.description,
        title: gift.title,
        link: gift.type === 'LINK' ? gift.link : undefined,
        pdf: gift.type === 'PDF' ? gift.pdfFileName : undefined,
        picturePath: gift.pictureFileName!
      })

      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === gift.id!)
        state.projects[projIdx].gifts[giftIdx] = updatedGift
      })
      return Promise.resolve(updatedGift)
    },
    updateGiftEventInfos: async (projectId: number, infos: GiftEventInfos) => {
      const maxEventId = get()
        .projects.map(proj => proj.gifts)
        .flatMap(g => g.map(gift => gift.event))
        .sort((g1, g2) => (g2?.id ?? 0) - (g1?.id ?? 0))
        .filter(e => e !== undefined)
        .map(e => e?.id!)[0]
      const updatedEvent: GiftEvent = {
        id: maxEventId + 1,
        occasion: infos.occasion,
        date: infos.date?.toISOString(),
        receivers: infos.receivers,
        sendingMode: 'MULTIPLE'
      }
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === infos.giftId)
        state.projects[projIdx].gifts[giftIdx].event = {
          id: updatedEvent.id,
          date: infos.date?.toISOString(),
          occasion: infos.occasion,
          receivers: updatedEvent.receivers ?? [],
          sendingMode: 'MULTIPLE'
        }
      })
      return updatedEvent
    },
    getOccasions: async () => {
      set(state => {
        state.occasionsByCategory = fakeOccasionsByCategory
      })
      return Promise.resolve(fakeOccasionsByCategory)
    },
    updateAnswer: (projectId: number, _: number, answer: string) => {
      const { answers } = get().projects.find(proj => proj.id === projectId)?.gifts[0]
        .openingGame ?? {
        question: undefined,
        answers: undefined
      }
      const success =
        answers
          ?.map(s => s.trim().toLocaleLowerCase())
          .includes(answer.trim().toLocaleLowerCase()) ?? false
      return Promise.resolve({ success })
    },
    retrieveGift: async (projectId: number, giftId: number) => {
      const gift = get()
        .projects.find(proj => proj.id === projectId)
        ?.gifts?.find(g => g.id === giftId)
      set(state => {
        state.openedGift = gift!
      })
      return Promise.resolve(gift!)
    },
    getProject: (projectId: number) => {
      const project = projects.find(proj => proj.id === projectId)
      if (project === undefined) {
        throw new Error('non existing projectId !')
      }
      set(state => {
        state.openedProject = project
        if ((get().projects.filter(proj => proj.id === project.id)?.length ?? 0) === 0) {
          state.projects.push(project)
        }
      })
      return Promise.resolve(project as Project)
    },
    // first param projectId, second is giftId
    sendGiftByEmail: async () => {
      return true
    },
    getTemplates: async () => {
      set(state => {
        state.templates = get().templates
      })
      return templates
    },
    chooseTemplate: async (templateId: number) => {
      set(state => {
        state.currentTemplateId = templateId
      })
    },
    updateGiftTemplate: async (projectId: number, giftId: number, templateId: number) => {
      const updatedTemplate = templates.find(template => template.id === templateId)
      set(state => {
        const projectIdx = get().projects.findIndex(proj => proj.id === projectId)
        if (projectIdx === -1) {
          throw new Error(`project ${projectId} not found!`)
        }
        const giftIdx = get().projects[projectIdx].gifts.findIndex(gift => gift.id === giftId)
        if (giftIdx === -1) {
          throw new Error(`gift ${giftId} was not found for project ${projectId}`)
        }

        state.projects[projectIdx].gifts[giftIdx].template = updatedTemplate
      })
      return updatedTemplate!
    },
    getLayoutPacks: async () => {
      const layoutPacksWithOrderedLayouts = layoutPacks.map(layoutPack => ({
        ...layoutPack,
        layouts: orderLayoutsByRole(layoutPack.layouts)
      }))
      set(state => {
        state.layoutPacks = layoutPacksWithOrderedLayouts
      })
      return layoutPacksWithOrderedLayouts
    },
    applyLayoutPackToGift: async (
      projectId: number,
      giftId: number,
      layoutPackId: number
    ): Promise<{ success: boolean }> => {
      set(state => {
        const currentTemplateId = get().currentTemplateId
        const templateIdx = get().templates.findIndex(template => template.id === currentTemplateId)
        const stateTemplateIdx = state.templates.findIndex(
          template => template.id === currentTemplateId
        )
        const templateName = get().templates[templateIdx].name

        const giftLayouts = (
          get().layoutPacks.find(lp => lp.id === layoutPackId)?.layouts ?? []
        ).map(layout =>
          Object.assign(layout, {
            kind: 'REAL',
            pictures: layout.pictures.map(
              p =>
                `public_assets/${projectId}/pictures/${giftId}/${PathHelper.extractFileName(
                  p.path
                )}`
            ),
            texts: [...layout.texts],
            cssPath: `public_assets/${projectId}/styles/${giftId}/${templateName}_${layout.role.toLocaleLowerCase()}.css`,
            templateId: currentTemplateId,
            giftId
          })
        )

        state.templates[stateTemplateIdx].layouts.push(...giftLayouts)
      })

      return { success: true }
    },
    refreshCurrentGift: async () => {
      set(state => {
        const currentProjectIdx = state.projects.findIndex(
          proj => proj.id === state.currentProjectId
        )
        const currentGift =
          (state.projects[currentProjectIdx].gifts.length > 0 &&
            state.projects[currentProjectIdx].gifts[0]) ||
          undefined
        if (!currentGift) {
          throw new Error('[refreshCurrentGift] missing current gift')
        }
        const currentTemplateIdx = get().templates.findIndex(
          template => template.id === state.currentTemplateId
        )
        currentGift.template = get().templates[currentTemplateIdx]
      })
    },
    updateGiftSendingMode: async (
      projectId: number,
      giftId: number,
      sendingMode: 'MULTIPLE' | 'UNIQUE' | undefined
    ) => {
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === giftId)
        const event = state.projects[projIdx].gifts[giftIdx].event
        if (event !== undefined) {
          event.sendingMode = sendingMode
        }
      })
    },
    initGiftEvent: async (projectId: number, giftId: number) => {
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === giftId)
        state.projects[projIdx].gifts[giftIdx].event = {
          date: undefined,
          id: undefined,
          occasion: undefined,
          receivers: undefined,
          sendingMode: undefined
        }
      })
    },
    setCurrentReceiver: async (receiverTrackingId: string) => {
      set(state => {
        state.currentReceiverTrackingId = receiverTrackingId
      })
    },
    uploadLayoutPicture: async () => {
      // nothing to do here, that's for backend only
    },
    updateLayoutItem: async (command: UpdateLayoutItemCommand) => {
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === command.projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === command.giftId)
        const layoutIdx = state.projects[projIdx].gifts[giftIdx].template!.layouts.findIndex(
          layout => layout.id === command.layoutId
        )
        if (command.itemType === 'TEXT') {
          const textIdx = state.projects[projIdx].gifts[giftIdx].template!.layouts[
            layoutIdx
          ].texts.findIndex(t => t.id === command.layoutItemId)
          state.projects[projIdx].gifts[giftIdx].template!.layouts[layoutIdx].texts[textIdx].text =
            command.value
          return
        }
        const pictureIdx = state.projects[projIdx].gifts[giftIdx].template!.layouts[
          layoutIdx
        ].pictures.findIndex(pict => pict.id === command.layoutItemId)
        state.projects[projIdx].gifts[giftIdx].template!.layouts[layoutIdx].pictures[
          pictureIdx
        ].path = command.value
      })
    },
    updateGiftOpeningMode: async (
      projectId: number,
      giftId: number,
      openingMode: OpeningMode,
      openingDate?: Date,
      openingGame?: OpeningGame
    ) => {
      set(state => {
        const updateOpeningBody = {
          openingMode,
          openingDate,
          openingGame
        }
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === giftId)
        state.projects[projIdx].gifts[giftIdx].openingMode = updateOpeningBody.openingMode
        state.projects[projIdx].gifts[giftIdx].openingDate =
          updateOpeningBody.openingDate?.toISOString()
        state.projects[projIdx].gifts[giftIdx].openingGame = updateOpeningBody.openingGame
      })
      return {
        openingMode,
        openingDate: openingDate?.toISOString(),
        openingGame
      }
    },
    getAllClients: async () => {
      return []
    },
    renameProject: async (projectId: number, newName: string) => {
      const project = get().projects.find(proj => proj.id === projectId)
      set(state => {
        const projIdx = state.projects.findIndex(proj => proj.id === projectId)
        state.projects[projIdx] = {
          ...project,
          id: projectId,
          name: newName,
          createdAt: project?.createdAt ?? now(),
          updatedAt: now(),
          gifts: project?.gifts ?? [],
          status: project?.status ?? 'IN_PROGRESS',
          creator: {}
        }
      })
      return { ...project, name: newName } as Project
    },
    getClientCommands(clientId: number): Promise<Command[]> {
      return Promise.resolve(commands.filter(cmd => cmd.client.id === clientId))
    },
    reserveGift(projectId: number, giftId: number, commandId: number): Promise<Command> {
      const cmd = commands.find(c => c.id === commandId)
      const gift = get()
        .projects.find(proj => proj.id === projectId)
        ?.gifts?.find(g => g.id === giftId)
      if (cmd === undefined || gift === undefined) {
        throw new Error('[inMemoryProjectStore_reserveGift] missing gift or command')
      }

      if ((gift.event?.receivers?.length ?? 0) === 0) {
        throw new Error('[inMemoryProjectStore_reserveGift] cannot reserve a receiverless gift')
      }

      return Promise.resolve({
        ...cmd,
        remaining_knots: cmd.remaining_knots - (gift.event?.receivers?.length ?? 0)
      })
    },
    setCurrentGiftCommand(projectId: number, giftId: number, command: Command) {
      set(state => {
        const projectIdx = state.projects.findIndex(proj => proj.id === projectId)
        const giftIdx = state.projects[projectIdx].gifts.findIndex(g => g.id === giftId)
        state.projects[projectIdx].gifts[giftIdx].command = command
      })
      return Promise.resolve()
    },
    updateOpeningGameState: (isPassed: boolean) => {
      set(state => {
        state.isOpeningGamePassed = isPassed
      })
    },
    notifyCreatorGiftOpening: async () => {
      return { success: true }
    },
    getReceiver: async () => {
      return {} as Receiver
    }
  }))
)

const producesUseInMemoryProjectStore = () => useInMemoryProjectStore

export default producesUseInMemoryProjectStore
