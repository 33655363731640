import useSendGiftByEmailUseCase from '../useCases/sendGiftByEmailUseCase'
import { Alert, AlertIcon, CloseButton, Input, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RefObject, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useProjectStore from '../stores/projectStore'
import './ExperienceDrawer.css'

interface DrawerProps {
  isOpen: boolean
  nextRef: RefObject<HTMLElement>
}

export function Drawer(props: DrawerProps) {
  const navigate = useNavigate()
  const intl = useIntl()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const { emailSent, sendByEmailError, getReceiver, sendGiftByEmail, nSub, giftId, projectId } =
    useSendGiftByEmailUseCase(giftClient)

  const [askForEmailMode, setAskForEmail] = useState(false)
  const [receiverEmail, setReceiverEmail] = useState(undefined as string | undefined)
  const [emailAction, setEmailAction] = useState(false)

  const { isOpen: isEmailSuccessVisible, onClose: onEmailSuccessClose } = useDisclosure({
    defaultIsOpen: true
  })

  useEffect(() => {
    getReceiver(Number(projectId), Number(giftId)).then(receiver =>
      setReceiverEmail(receiver?.email)
    )
  }, [getReceiver, giftId, projectId])

  function isEmailValid(email: string | undefined): boolean {
    if (!email) return false
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    if (!sendByEmailError && emailAction && emailSent) {
      onEmailSuccessClose()
      props.nextRef.current?.click()
    }
  }, [emailAction, emailSent, sendByEmailError])

  return (
    <>
      <div className={`drawer-backdrop ${props.isOpen && 'show-backdrop'}`}></div>
      <aside
        className={`drawer ${props.isOpen && 'open-drawer'}`}
        style={{
          backgroundColor: giftClient.openedGift?.template?.solidBackground3
        }}
      >
        <img className={'knot'} alt={'Noeud'} src={process.env.PUBLIC_URL + '/noeud-rouge.png'} />
        {!askForEmailMode ? (
          <>
            <button
              type={'button'}
              className={'btn-primary'}
              onClick={() => setAskForEmail(true)}
              style={{
                backgroundColor: giftClient.openedGift?.template?.callToActionBackgroundColor
              }}
            >
              <FormattedMessage
                description='signatureSubtitle'
                defaultMessage='Récupérer mon cadeau'
              />
            </button>
            <button
              type={'button'}
              className={'btn-secondary'}
              onClick={() =>
                navigate(
                  `/projects/${giftClient.openedGift?.project.id}/gifts/${giftClient.openedGift?.id}/loading/swipe?nSub=${nSub}`
                )
              }
              style={{
                backgroundColor: giftClient.openedGift?.template?.solidBackground3
              }}
            >
              <FormattedMessage
                description='signatureSubtitle'
                defaultMessage='Rejouer le présent'
              />
            </button>
            <img className={'logo'} src={'/present_noir.svg'} alt={'Logo Présent'} />
          </>
        ) : (
          <>
            <Input
              fontWeight='600'
              color='var(--var-text-color)'
              borderRadius='6px'
              size='lg'
              height='58px'
              placeholder={intl.formatMessage({
                description: 'experiencePageEmailInputPlaceholder',
                defaultMessage: 'Saisissez votre adresse mail'
              })}
              value={receiverEmail}
              onChange={e => setReceiverEmail(e.target.value)}
            />
            <button
              className={`btn-primary ${isEmailValid(receiverEmail) ? '' : 'disabled'}`}
              style={{
                backgroundColor: giftClient.openedGift?.template?.callToActionBackgroundColor
              }}
              disabled={!isEmailValid(receiverEmail)}
              onClick={() => {
                if (emailAction) return
                sendGiftByEmail(receiverEmail)
                setEmailAction(true)
              }}
              type='button'
            >
              <FormattedMessage
                description='experiencePageConfirmEmail'
                defaultMessage='Confirmer mon email'
              ></FormattedMessage>
            </button>
          </>
        )}
        {emailSent && !sendByEmailError && isEmailSuccessVisible && (
          <Alert status='success'>
            <AlertIcon />
            <FormattedMessage
              description='experiencePageEmailSuccessfullySentMessage'
              defaultMessage='Votre présent vous a été envoyé par e-mail.'
            />
            <CloseButton
              alignSelf='flex-start'
              position='relative'
              right={-1}
              top={-1}
              onClick={onEmailSuccessClose}
            />
          </Alert>
        )}
      </aside>
    </>
  )
}
