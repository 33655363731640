import { z } from 'zod'
import { useIntl } from 'react-intl'
import { useCallback, useRef, useState } from 'react'

export type TextFieldProps<T> = {
  initialValue: string
  minSize: number
  maxSize: number
  uploadFieldDesc: string
  synchronize?: (fieldValue: string) => Promise<T>
}

export function useTextField<T>({
  initialValue,
  minSize,
  maxSize,
  uploadFieldDesc,
  synchronize
}: TextFieldProps<T>) {
  const intl = useIntl()
  const [field, setField] = useState(initialValue)
  const [fieldLoading, setFieldLoading] = useState(false)
  const [fieldUpdateError, setFieldUpdateError] = useState(null as string | null)

  const hasFieldChanged = useRef(false)

  const validateField = useCallback(
    (title: string) => {
      try {
        z.string().min(minSize).max(maxSize).parse(title)
        return { success: true, error: null }
      } catch (err) {
        const error = intl.formatMessage(
          {
            description: 'configureLinkGiftInvalidTitleMessage',
            defaultMessage: 'Ce champ doit contenir entre {minSize} et {maxSize} caractères.'
          },
          { minSize, maxSize }
        )
        return { success: false, error }
      }
    },
    [intl, minSize, maxSize]
  )

  const changeField = useCallback(async (pTitle: string) => {
    setField(pTitle)
    hasFieldChanged.current = true
  }, [])

  const updateField = useCallback(
    async (fieldValue: string) => {
      if (!hasFieldChanged.current || fieldValue === '') {
        return
      }
      try {
        setFieldLoading(true)
        if (synchronize !== undefined) {
          await synchronize(fieldValue)
        }
        hasFieldChanged.current = false
      } catch (err) {
        hasFieldChanged.current = true
        setFieldUpdateError(
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {eventDesc}."
            },
            { eventDesc: uploadFieldDesc }
          )
        )
      } finally {
        setFieldLoading(false)
      }
    },
    [intl, synchronize, uploadFieldDesc]
  )

  return {
    field,
    fieldLoading,
    fieldUpdateError,
    changeField,
    validateField,
    updateField,
    hasFieldChanged: hasFieldChanged.current
  }
}
