import { jsPDF } from 'jspdf'
import ProjectClient from '../domain/adapters/secondary/projectClient'
import { useCallback, useState } from 'react'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'

export default function useSaveReceiversUseCase(projectClient: ProjectClient) {
  const receivers =
    (projectClient.projects.find(proj => proj.id === projectClient.currentProjectId)?.gifts ??
      [])[0]?.event?.receivers ?? []

  const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const save = useCallback(async (x: number, y: number, width: number, windowWidth: number) => {
    const doc = new jsPDF()
    setIsSaveLoading(true)
    return new Promise<void>(resolve => {
      doc.html(document.getElementsByClassName('tableReceivers')[0] as HTMLElement, {
        callback: function (doc) {
          doc.save('destinataires_present.pdf')
          resolve()
          setIsSaveLoading(false)
        },
        x,
        y,
        width,
        windowWidth
      })
    })
  }, [])

  return {
    // save receivers
    receivers,
    save,
    isSaveLoading,

    // navigation
    currentProjectId,
    currentStoreGift
  }
}
