import useProjectStore from '../stores/projectStore'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import SmallText from '../components/SmallText'
import Page from '../components/Page'
import { getSteps } from './breadcrumbStepsHelper'
import { Flex, FormLabel, HStack, List, ListItem } from '@chakra-ui/react'
import SecondaryButton from '../components/SecondaryButton'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/PrimaryButton'

import './SelectOccasion.css'
import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useChooseOccasionUseCase from '../useCases/chooseOccasionUseCase'

export default function SelectOccasion(): React.JSX.Element {
  const navigate = useNavigate()

  const occasionClient = useProjectStore(SlicesHelper.getOccasionSlice)
  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const {
    // occasions
    occasionsByCategory,
    selectedOccasion,
    occasionsError,
    updateError,
    isCustomOccasion,

    getOccasions,
    occasionSelected,
    // navigation
    currentProjectId,
    currentStoreGift
  } = useChooseOccasionUseCase(occasionClient, giftClient)

  useEffect(() => {
    getOccasions().then()
  }, [getOccasions])

  const steps: Step[] = getSteps('Évènement', currentStoreGift?.reachedStep)

  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='selectOccasionTitleLabel'
          defaultMessage='Que fêtez vous ?'
        />
      </Header2>
      <SmallText mb='4px'>
        <FormattedMessage
          description='selectOccasionSubtitle'
          defaultMessage="Appuyez sur l'occasion correspondante."
        />
      </SmallText>
      <SmallText mb='24px'>
        <FormattedMessage
          description='selectOccasionSubSubtitle'
          defaultMessage="Si elle n'est pas dans la liste, cliquez sur Autre."
        />
      </SmallText>

      <List styleType='none' margin='0px auto'>
        {Object.entries(occasionsByCategory.occasions.categories).map(category => (
          <div key={'category_' + category}>
            <ListItem
              fontFamily='Nunito Sans'
              fontWeight='700'
              lineHeight='24px'
              fontSize='lg'
              color='var(--var-text-color)'
              mb='8px'
              textTransform='capitalize'
            >
              {category[0]}
            </ListItem>
            <HStack gap='12px' mb='24px'>
              {category[1].map(occasion => (
                <FormLabel
                  key={occasion}
                  display='flex'
                  mx='0px'
                  alignItems='center'
                  padding='0px 12px'
                  borderRadius='6px'
                  bgColor='rgba(57, 119, 121, 0.05)'
                  color='#17444D'
                  py='4px'
                  lineHeight='24px'
                  fontFamily='Nunito Sans'
                  className={
                    selectedOccasion === occasion ||
                    (occasion === 'autre évènement' && isCustomOccasion)
                      ? 'selected-occasion'
                      : 'unselected-occasion'
                  }
                  onClick={async () => await occasionSelected(category[0], occasion)}
                >
                  {occasion}
                </FormLabel>
              ))}
            </HStack>
          </div>
        ))}
      </List>
      {occasionsError !== null ? (
        <Flex w='554px' alignSelf='center' mb='16px' color='red.500'>
          {occasionsError}
        </Flex>
      ) : null}
      {updateError !== null ? (
        <Flex w='554px' alignSelf='center' mb='16px' color='red.500'>
          {updateError}
        </Flex>
      ) : null}
      <Flex w='full' justifyContent='center' gap='24px' mt='80px' mb='24px'>
        <SecondaryButton
          as={DomLink}
          variant='outline'
          onClick={e => {
            e.preventDefault()
            if (currentStoreGift) {
              navigate(
                `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/${
                  currentStoreGift.event?.sendingMode === 'MULTIPLE'
                    ? 'receiversDetails'
                    : 'receiversUnique'
                }`
              )
            }
          }}
        >
          <FormattedMessage description='generalReturn' defaultMessage='Retour' />
        </SecondaryButton>
        <PrimaryButton
          isDisabled={(selectedOccasion?.length ?? 0) === 0}
          className={(selectedOccasion?.length ?? 0) === 0 ? 'disabled-primary-btn' : ''}
          onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault()
            if (selectedOccasion === 'autre évènement' || isCustomOccasion) {
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/otherOccasion`)
              return
            }
            navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/eventDate`)
          }}
        >
          <FormattedMessage description='generalNext' defaultMessage='Suivant' />
        </PrimaryButton>
      </Flex>
    </Page>
  )
}
