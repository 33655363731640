import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

import './Page.css'
export default function Page(props: FlexProps): React.JSX.Element {
  return (
    <Flex
      direction='column'
      w='full'
      bgColor='var(--var-page-bg)'
      p={'40px 12px 24px 12px;'}
      {...props}
    >
      {props.children}
    </Flex>
  )
}
