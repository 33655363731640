import React from 'react'
import { BoxProps, HStack } from '@chakra-ui/react'
import { Template } from '../domain/template'
import { Layout, LayoutRole } from '../domain/layout'
import * as CSS from 'csstype'
import EditLayoutCard from './EditLayoutCard'

export type EditLayoutsStackProps = BoxProps & {
  layouts: Layout[]
  template: Template
  excludedRole: LayoutRole
  cardWidth?: CSS.Property.Width | number
}

export default function EditLayoutsStack({
  template,
  layouts,
  excludedRole,
  cardWidth,
  ...props
}: EditLayoutsStackProps): React.JSX.Element {
  const colors = [template.solidBackground1, template.solidBackground2, template.solidBackground3]
  return (
    <HStack spacing={'24px'} alignSelf='center' {...props}>
      {layouts
        .filter(layout => layout.role !== excludedRole)
        .map((layout, idx) => (
          <EditLayoutCard
            key={layout.id}
            index={idx}
            template={template}
            layout={layout}
            w={cardWidth}
            p='12px'
            backgroundColor={colors[idx]}
          />
        ))}
    </HStack>
  )
}
