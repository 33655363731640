import { GiftEventInfos } from '../stores/projectStore'
import { useIntl } from 'react-intl'
import { useCallback, useMemo, useState } from 'react'
import { Receiver } from '../domain/receiver'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import GiftClient from '../domain/adapters/secondary/giftClient'

export default function useFinalizeReceiversUseCase(giftClient: GiftClient) {
  const intl = useIntl()

  const { currentStoreGift, currentProjectId } = useGiftStoreDependencies()

  const receivers = useMemo(
    () =>
      giftClient.currentGiftReceivers.length > 0
        ? giftClient.currentGiftReceivers
        : currentStoreGift?.event?.receivers ?? [],
    [giftClient.currentGiftReceivers, currentStoreGift?.event?.receivers]
  )

  const updateGiftEventInfos = giftClient.updateGiftEventInfos

  const [updateGiftEventError, setUpdateGiftEventError] = useState(null as string | null)

  const updateGiftEvent = useCallback(async () => {
    function hasMismatchOnSomeProp(r: Receiver) {
      return (rec: Receiver) =>
        rec.email !== r.email ||
        rec.firstname !== r.firstname ||
        rec.name !== r.name ||
        rec.kind !== r.kind
    }

    function anyMismatchExpectedReceivers(r: Receiver) {
      return receivers.filter(hasMismatchOnSomeProp(r)).length > 0
    }

    try {
      const hasReceivers = (currentStoreGift?.event?.receivers?.length ?? 0) > 0
      const allReceiversMatchAboutToUpdateOnes =
        currentStoreGift?.event?.receivers?.filter(anyMismatchExpectedReceivers).length === 0
      if (hasReceivers && allReceiversMatchAboutToUpdateOnes) {
        return
      }
      const giftEventInfos: GiftEventInfos = {
        giftId: currentStoreGift?.id || 0,
        receivers,
        sendingMode: currentStoreGift?.event?.sendingMode
      }

      if ((currentStoreGift?.event?.occasion?.length ?? 0) > 0) {
        giftEventInfos.occasion = currentStoreGift?.event?.occasion
        giftEventInfos.category = currentStoreGift?.event?.category
      }

      await updateGiftEventInfos(currentProjectId, giftEventInfos)
    } catch (err) {
      const errMsg =
        (err as Error)?.message ??
        intl.formatMessage({
          description: 'generalUnknownErrorMessage',
          defaultMessage: "Une erreur s'est produite."
        })
      setUpdateGiftEventError(errMsg)
    }
  }, [
    currentProjectId,
    currentStoreGift?.event?.category,
    currentStoreGift?.event?.occasion,
    currentStoreGift?.event?.receivers,
    currentStoreGift?.event?.sendingMode,
    currentStoreGift?.id,
    intl,
    receivers,
    updateGiftEventInfos
  ])

  return {
    // receivers
    receivers,
    updateGiftEvent,
    updateGiftEventError,
    // navigation
    currentProjectId,
    currentStoreGift
  }
}
