import React, { useEffect } from 'react'
import Page from '../components/Page'
import { Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import { FormattedMessage } from 'react-intl'
import SmallText from '../components/SmallText'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import { getSteps } from './breadcrumbStepsHelper'
import useProjectStore from '../stores/projectStore'
import { Link as DomLink, useNavigate } from 'react-router-dom'

import './HideMainNavbar.css'
import useChooseReceiverModeUseCase from '../useCases/chooseReceiverModeUseCase'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
function ReceiverList(props: {
  children: React.ReactNode
  value: 'UNIQUE' | 'MULTIPLE' | undefined
  onChange: (value: string) => void
}): React.JSX.Element {
  return (
    <RadioGroup value={props.value} onChange={props.onChange}>
      <Stack direction='column' spacing='24px'>
        {props.children}
      </Stack>
    </RadioGroup>
  )
}

function ReceiverListItem(props: { children: React.ReactNode; value: string }): React.JSX.Element {
  return (
    <Flex backgroundColor='#647D990D' p='16px 24px' borderRadius='8px'>
      <Radio value={props.value} colorScheme='teal' gap='16px' size='lg'>
        {props.children}
      </Radio>
    </Flex>
  )
}

export default function ReceiversMode(): React.JSX.Element {
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)

  const {
    // mode selection
    selectedMode,
    updateSelectedMode,
    initGiftEvent,
    // other
    currentProjectId,
    currentStoreGift
  } = useChooseReceiverModeUseCase(giftClient)

  useEffect(() => {
    if (selectedMode !== undefined) {
      return
    }
    if (currentStoreGift && currentStoreGift.id) {
      initGiftEvent(currentProjectId, currentStoreGift.id)
    }
  }, [currentProjectId, currentStoreGift?.id, selectedMode, initGiftEvent])

  const steps: Step[] = getSteps('Évènement', currentStoreGift?.reachedStep)

  return (
    <Page gap='80px'>
      <ProjectBreadcrumb
        steps={steps}
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />

      <Flex flexDir='column' maxW='554px' w='100%' mx='auto' gap='80px'>
        <Flex flexDir='column' gap='24px'>
          <Flex flexDir='column' gap='4px'>
            <Header2 alignSelf='center'>
              <FormattedMessage
                description='evenreceiversModeTitleLabel'
                defaultMessage='À qui est destiné ce cadeau ?'
              />
            </Header2>
            <SmallText>
              <FormattedMessage
                description='receiversModeSubtitle'
                defaultMessage='Choisissez votre type de destinataire'
              />
            </SmallText>
          </Flex>

          <ReceiverList
            value={selectedMode}
            onChange={async value => {
              await updateSelectedMode(value as 'UNIQUE' | 'MULTIPLE')
            }}
          >
            <ReceiverListItem value='UNIQUE'>
              <Header2 fontSize='xl'>
                <FormattedMessage
                  description='receiversModeSingleTitle'
                  defaultMessage='Un seul destinataire'
                />
              </Header2>
              <Text fontSize='sm'>
                <FormattedMessage
                  description='receiversModeSingleDesc'
                  defaultMessage='Envoyez votre cadeau à une seule personne'
                />
              </Text>
            </ReceiverListItem>
            <ReceiverListItem value='MULTIPLE'>
              <Header2 fontSize='xl'>
                <FormattedMessage
                  description='receiversModeMultipleTitle'
                  defaultMessage='Multi-destinataires'
                />
              </Header2>
              <Text fontSize='sm'>
                <FormattedMessage
                  description='receiversModeMultipleDesc'
                  defaultMessage='Importez votre base de données avec notre template CSV'
                />
              </Text>
            </ReceiverListItem>
          </ReceiverList>
        </Flex>

        <Flex justifyContent='center' gap='12px'>
          <SecondaryButton
            as={DomLink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              const prevPathSelector: Record<string, string> = {
                PDF: `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/pdfInfos`,
                LINK: `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/linkInfos`,
                MSG: `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/creation`
              }
              if (!(currentStoreGift.type in prevPathSelector)) {
                throw new Error(`unhandled link type! (${currentStoreGift.type})`)
              }
              navigate(prevPathSelector[currentStoreGift.type])
            }}
          >
            <FormattedMessage description='generalPass' defaultMessage='Passer' />
          </SecondaryButton>
          <PrimaryButton
            isDisabled={!selectedMode}
            onClick={e => {
              e.preventDefault()
              if (selectedMode === 'MULTIPLE') {
                navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/receivers`)
              } else if (selectedMode === 'UNIQUE') {
                navigate(
                  `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/receiversUnique`
                )
              }
            }}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      </Flex>
    </Page>
  )
}
