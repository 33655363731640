import GiftEvent from './giftEvent'
import OpeningGame from './openingGame'
import { Template } from './template'
import { Client } from './client'
import { getHours, getMinutes } from 'date-fns'
import { LayoutPack } from './layoutPack'
import { Command } from './command'

export type GiftType = 'LINK' | 'PDF' | 'MSG'

export type GiftStep = 'GIFT_TYPE' | 'GIFT_INFOS'

export type OpeningMode = 'NONE' | 'DATE' | 'GAME' | 'GAME_AND_DATE'

export type GiftCompletedStep = 'NONE' | 'GIFT' | 'EVENT' | 'EDITION' | 'OPENING_GAME' | 'PACKAGING'

export default interface Gift {
  id?: number
  name?: string
  title?: string
  description?: string
  type: GiftType
  step: GiftStep
  link?: string
  pdf?: string
  picturePath?: string
  createdAt: string
  updatedAt: string
  project: { id: number }
  event?: GiftEvent
  openingMode?: OpeningMode
  openingGame?: OpeningGame
  openingDate?: string
  template?: Template
  sender?: Client
  layoutPackId?: number
  layoutPack?: LayoutPack
  reachedStep?: GiftCompletedStep
  command?: Command
  visualizationLink: string
  hasBeenOpened: boolean
}

export function getGiftDate(rawDate: string | undefined): Date | undefined {
  if (rawDate === undefined) {
    return rawDate
  }
  return new Date(rawDate)
}

export function getGiftLocalDateHours(rawDate: string | undefined): number | undefined {
  if (rawDate === undefined) {
    return rawDate
  }
  return getHours(new Date(rawDate))
}

export function getGiftLocalDateMinutes(rawDate: string | undefined): number | undefined {
  if (rawDate === undefined) {
    return rawDate
  }
  return getMinutes(new Date(rawDate))
}
