import { FormattedMessage } from 'react-intl'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useProjectStore from '../stores/projectStore'

import './ExperienceSignature.css'

export default function Signature() {
  const projectClient = useProjectStore(SlicesHelper.getProjectClient)
  const giftClient = useProjectStore(SlicesHelper.getGiftClient)

  return (
    <main
      className={`container-3`}
      style={{
        backgroundImage:
          giftClient.openedGift?.template?.signatureBackgroundImage === undefined
            ? undefined
            : `url(/${giftClient.openedGift?.template?.signatureBackgroundImage}.png)`
      }}
    >
      <h1>
        <FormattedMessage
          description='signatureTitle'
          defaultMessage='Il est temps de découvrir ton cadeau'
        />
      </h1>
      <h2>
        <FormattedMessage description='signatureSubtitle' defaultMessage='Offert par' />
        &nbsp;
        {projectClient.openedProject?.creator?.sign}
      </h2>
    </main>
  )
}
