import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import SmallText from '../components/SmallText'
import { getSteps } from './breadcrumbStepsHelper'
import Page from '../components/Page'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import { Card, Flex } from '@chakra-ui/react'
import { Calendar } from 'react-calendar'
import useProjectStore from '../stores/projectStore'

import './HideMainNavbar.css'
import useChooseOpeningDateUseCase from '../useCases/chooseOpeningDateUseCase'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'

export default function OpeningDate(): React.JSX.Element {
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const {
    currentProjectId,
    currentStoreGift,
    disableBeforeToday,
    formatWeekday,
    tileClassName,
    hasDateChanged,
    updateOpeningDate,
    updateError,
    date,
    dateChanged,
    activeStartDateChanged
  } = useChooseOpeningDateUseCase(giftClient)

  const steps: Step[] = getSteps("Jeux d'ouverture", currentStoreGift?.reachedStep)
  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='openingDateTitleLabel'
          defaultMessage='Quand à lieu cet évènement ?'
        />
      </Header2>
      <SmallText mb='24px'>
        <FormattedMessage
          description='openingDateSubtitle'
          defaultMessage='Sélectionnez le jour où vous comptez offrir ce cadeau en cliquant sur le calendrier.'
        />
      </SmallText>

      <Card
        w='554px'
        alignSelf='center'
        className='calendar-card'
        backgroundColor='transparent'
        shadow='none'
        border='0px'
        _hover={{
          backgroundColor: 'transparent',
          shadow: 'none',
          border: '0px'
        }}
      >
        <Calendar
          onChange={value => dateChanged(value as Date)}
          value={date}
          view='month'
          tileClassName={tileClassName}
          tileDisabled={disableBeforeToday}
          formatShortWeekday={formatWeekday}
          onActiveStartDateChange={({ activeStartDate }) =>
            activeStartDateChanged(activeStartDate as Date)
          }
          nextLabel={
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.70504 6.5L8.29504 7.91L12.875 12.5L8.29504 17.09L9.70504 18.5L15.705 12.5L9.70504 6.5Z'
                fill='#49454F'
              />
            </svg>
          }
          prevLabel={
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.705 7.91L14.295 6.5L8.29504 12.5L14.295 18.5L15.705 17.09L11.125 12.5L15.705 7.91Z'
                fill='#49454F'
              />
            </svg>
          }
        />
      </Card>
      {updateError && <Flex color='red.500'>{updateError}</Flex>}
      <Flex w='full' justifyContent='center' gap='24px' mt='80px' mb='24px'>
        {currentStoreGift && (
          <>
            <SecondaryButton
              as={DomLink}
              variant='outline'
              onClick={e => {
                e.preventDefault()
                if (currentStoreGift?.openingMode === 'GAME_AND_DATE') {
                  navigate(
                    `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/openingGame/answers`
                  )
                  return
                }
                navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/openingMode`)
              }}
            >
              <FormattedMessage description='generalReturn' defaultMessage='Retour' />
            </SecondaryButton>

            <PrimaryButton
              isDisabled={date === undefined && (!hasDateChanged || updateError !== null)}
              className={
                date === undefined && (!hasDateChanged || updateError != null)
                  ? 'disabled-primary-btn'
                  : ''
              }
              onClick={async e => {
                e.preventDefault()
                const success = await updateOpeningDate()
                if (!success) {
                  return
                }
                navigate(
                  `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/openingDateHour`
                )
              }}
            >
              <FormattedMessage description='generalNext' defaultMessage='Suivant' />
            </PrimaryButton>
          </>
        )}
      </Flex>
    </Page>
  )
}
