import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import useProjectStore from '../stores/projectStore'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import TemplateClient from '../domain/adapters/secondary/templateClient'
import ProjectClient from '../domain/adapters/secondary/projectClient'

export default function useApplyLayoutPackUseCase(
  templateClient: TemplateClient,
  projectClient: ProjectClient
) {
  const intl = useIntl()

  const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()
  const currentStoreTemplateId = useProjectStore(state => state.currentTemplateId)
  const currentTemplateId =
    currentStoreTemplateId > 0 ? currentStoreTemplateId : currentStoreGift?.template?.id ?? -1

  const currentTemplate = templateClient.templates.find(
    template => template.id === currentTemplateId
  )

  const layoutPacks = templateClient.layoutPacks

  const applyLayoutPackToGift = templateClient.applyLayoutPackToGift
  const refreshCurrentGift = projectClient.refreshCurrentGift

  const [layouts, setLayouts] = useState(
    (currentStoreGift?.layoutPack?.layouts?.length ?? 0) > 0
      ? currentStoreGift?.layoutPack?.layouts
      : layoutPacks[0].layouts
  )
  const [applyLayoutPackError, setApplyLayoutPackError] = useState(null as string | null)

  const [layoutPackId, setLayoutPackId] = useState(
    currentStoreGift?.layoutPack?.id ?? layoutPacks[0].id
  )

  const [isApplyLayoutsLoading, setIsApplyLayoutsLoading] = useState(false)

  const previewLayout = useMemo(() => {
    const previewLayout = layouts?.find(layout => layout.role === 'PREVIEW')
    if (previewLayout === undefined) {
      throw new Error('missing layout for role preview')
    }
    return previewLayout
  }, [layouts])

  const applyLayoutPack = useCallback(async () => {
    setIsApplyLayoutsLoading(true)
    try {
      if (
        currentStoreGift?.layoutPack?.id === layoutPackId &&
        layoutPackId !== undefined &&
        (currentStoreGift?.template?.layouts?.length ?? 0) > 0
      ) {
        return true
      }
      let apiReturn = { success: false }
      if (currentStoreGift && currentStoreGift.id) {
        apiReturn = await applyLayoutPackToGift(currentProjectId, currentStoreGift.id, layoutPackId)
      }
      await refreshCurrentGift()

      if (!apiReturn.success) {
        setApplyLayoutPackError(
          intl.formatMessage({
            description: 'layoutSelection',
            defaultMessage: "Une erreur s'est produite lors de l'application de la mise en page"
          })
        )
      }
      setIsApplyLayoutsLoading(false)
      return apiReturn.success
    } catch (err) {
      setApplyLayoutPackError(
        intl.formatMessage({
          description: 'layoutSelection',
          defaultMessage: "Une erreur s'est produite lors de l'application de la mise en page"
        })
      )
      setIsApplyLayoutsLoading(false)
      return false
    }
  }, [
    applyLayoutPackToGift,
    currentProjectId,
    currentStoreGift?.id,
    currentStoreGift?.layoutPack?.id,
    currentStoreGift?.template?.layouts?.length,
    intl,
    layoutPackId,
    refreshCurrentGift
  ])

  const layoutPackChanged = useCallback(
    (layoutPackId: string) => {
      const layoutPack = layoutPacks.find(layoutPack => layoutPack.id === Number(layoutPackId))
      if (layoutPack === undefined) {
        throw new Error('invalid select value: layout pack!')
      }
      setLayouts(layoutPack.layouts)
      setLayoutPackId(layoutPack.id)
    },
    [layoutPacks]
  )

  if (currentStoreGift === undefined || currentStoreGift.id === undefined) {
    throw new Error('missing current gift!')
  }
  if (currentTemplateId === -1 || currentTemplate === undefined) {
    throw new Error('invalid template selected!')
  }
  if (layouts === undefined) {
    throw new Error(`cannot find layouts for template ${currentTemplateId}!`)
  }

  return {
    // layoutPack
    applyLayoutPackError,
    previewLayout,
    layouts,
    layoutPacks,

    applyLayoutPack,
    layoutPackChanged,
    isApplyLayoutsLoading,

    // navigation
    currentProjectId,
    currentStoreGift,
    currentTemplateId,
    currentTemplate
  }
}
