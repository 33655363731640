import { getRenderItems, Layout, RenderItem } from '../domain/layout'
import { Picture } from '../domain/picture'
import { Text } from '../domain/text'
import { useIntl } from 'react-intl'
import { useCallback, useMemo, useState } from 'react'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import Gift from '../domain/gift'
import { UpdateLayoutItemCommandBuilder } from '../models/commands/updateLayoutItemCommand'
import { UploadLayoutPictureCommandBuilder } from '../models/commands/uploadLayoutPictureCommand'
import LayoutClient from '../domain/adapters/secondary/layoutClient'

export default function useEditLayoutUseCase(layoutClient: LayoutClient, layout: Layout) {
  const intl = useIntl()

  const { currentStoreGift } = useGiftStoreDependencies()

  const [renderItems, setRenderItems] = useState(
    getRenderItems(layout).map(renderItem => Object.assign(renderItem, { isEditable: true }))
  )
  const [updateLayoutItemError, setUpdateLayoutItemError] = useState(null as string | null)
  const [uploadPictureError, setUploadPictureError] = useState(null as string | null)
  const picturesCount = useMemo(
    () => renderItems.filter(r => r.type === 'picture').length,
    [renderItems]
  )
  const containerSizeByPictureCount = ['xl', 'lg', 'md', 'sm']

  const updateLayoutItem = layoutClient.updateLayoutItem
  const uploadLayoutPicture = layoutClient.uploadLayoutPicture

  if (currentStoreGift === undefined) {
    throw new Error('missing current gift!')
  }

  const toggleEditStatus = useCallback((id: number) => {
    setRenderItems(items =>
      items.map(item => {
        if (item.id === id) {
          return { ...item, isEditable: !item.isEditable }
        }
        return { ...item }
      })
    )
  }, [])

  const updateText = useCallback(
    async (newRenderItem: RenderItem, currentGift: Gift) => {
      if (currentGift.id) {
        let updateTextCmd = new UpdateLayoutItemCommandBuilder()
          .withProjectId(currentGift.project.id)
          .withGiftId(currentGift.id)
          .withLayoutId(layout.id)
          .withLayoutItemId(newRenderItem.id)
          .withItemType('TEXT')
          .withValue((newRenderItem as Text).text)
          .build()

        const text = intl.formatMessage({
          description: 'generalLayoutItemTypeText',
          defaultMessage: 'texte'
        })
        try {
          return await updateLayoutItem(updateTextCmd)
        } catch (_) {
          setUpdateLayoutItemError(
            intl.formatMessage(
              {
                description: 'editLayoutCardUpdateItemErrorMessage',
                defaultMessage:
                  "Une erreur s'est produite lors de la mise à jour du {itemType} {itemOrder} du role {role}"
              },
              {
                itemType: text,
                itemOrder: newRenderItem.typeIndex + 1,
                role: layout.role
              }
            )
          )
        }
      } else {
        throw Error('CurrentGift doesnt have id')
      }
    },
    [intl, layout.id, layout.role, updateLayoutItem]
  )

  async function fetchPicture(newRenderItem: RenderItem): Promise<Blob> {
    const newRenderAsPicture = newRenderItem as unknown as Picture
    if (!newRenderAsPicture.croppedImgDataUrl) {
      throw new Error('error invalid cropped url!')
    } else {
      const res = await fetch(newRenderAsPicture.croppedImgDataUrl, {
        method: 'GET'
      })
      return new Blob([await res.blob()], { type: 'image/png' })
    }
  }

  const buildUploadPictureCommand = useCallback(
    (renderItem: RenderItem, currentGift: Gift, picture: Blob) => {
      if (currentGift.id) {
        return new UploadLayoutPictureCommandBuilder()
          .withProjectId(currentGift.project.id)
          .withGiftId(currentGift.id)
          .withLayoutId(layout.id)
          .withPictureId(renderItem.id)
          .withPicture(picture)
          .build()
      }
    },
    [layout.id]
  )

  const updatePicture = useCallback(
    (newRenderItem: RenderItem, currentGift: Gift) => {
      if (currentGift.id) {
        let updateLayoutPictureCmd = new UpdateLayoutItemCommandBuilder()
          .withProjectId(currentGift.project.id)
          .withGiftId(currentGift.id)
          .withLayoutId(layout.id)
          .withItemType('PICTURE')
          .withLayoutItemId(newRenderItem.id)
          .withValue(
            `public_assets/${currentGift.project.id}/pictures/${currentGift.id}/${newRenderItem.id}.png`
          )
          .build()
        return updateLayoutItem(updateLayoutPictureCmd)
      }
    },
    [layout.id, updateLayoutItem]
  )

  const handleUploadPictureError = useCallback(
    (newRenderItem: RenderItem) => {
      setUploadPictureError(
        intl.formatMessage(
          {
            description: 'editLayoutCardUploadPictureErrorMessage',
            defaultMessage:
              "Une erreur s'est produite lors de la transmission de l'image {itemOrder} du role {role}"
          },
          {
            itemOrder: newRenderItem.typeIndex + 1,
            role: layout.role
          }
        )
      )
    },
    [intl, layout.role]
  )

  function clearErrors() {
    setUpdateLayoutItemError(null)
    setUploadPictureError(null)
  }

  const dec2hex = (dec: number) => dec.toString(16).padStart(2, '0')

  const generateId = useCallback((len: number) => {
    const arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }, [])

  const refreshRenderItem = useCallback(
    async (newRenderItem: RenderItem) => {
      if (newRenderItem.type === 'text') {
        setRenderItems(items =>
          items.map(item => {
            if (newRenderItem.id === item.id) {
              return { ...newRenderItem }
            }
            return { ...item }
          })
        )
        await updateText(newRenderItem, currentStoreGift)
        return clearErrors()
      }

      try {
        const picture = await fetchPicture(newRenderItem)
        const uploadPictureCommand = buildUploadPictureCommand(
          newRenderItem,
          currentStoreGift,
          picture
        )
        if (uploadPictureCommand) {
          await uploadLayoutPicture(uploadPictureCommand)
          const renderPicture = newRenderItem as Picture
          renderPicture.path = `public_assets/${currentStoreGift.project.id}/pictures/${
            currentStoreGift.id
          }/${renderPicture.id}.png?id=${generateId(4)}`

          setRenderItems(items =>
            items.map(item => {
              if (newRenderItem.id === item.id) {
                return { ...(renderPicture as RenderItem) }
              }
              return { ...item }
            })
          )
          await updatePicture(renderPicture as RenderItem, currentStoreGift)
          clearErrors()
        } else {
          throw Error('uploadPictureCommand is undefined')
        }
      } catch {
        handleUploadPictureError(newRenderItem)
      }
    },
    [
      buildUploadPictureCommand,
      currentStoreGift,
      generateId,
      handleUploadPictureError,
      updatePicture,
      updateText,
      uploadLayoutPicture
    ]
  )

  return {
    // edit picture or text
    picturesCount,
    containerSizeByPictureCount,
    renderItems,
    updateLayoutItemError,
    uploadPictureError,

    refreshRenderItem,
    toggleEditStatus
  }
}
