import { useCallback, useEffect, useState } from 'react'
import { Command } from '../domain/command'
import CommandClient from '../domain/adapters/secondary/commandClient'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'

export default function useSelectOrderUseCase(
  commandClient: CommandClient,
  selectedClientId: number
) {
  const { currentStoreGift } = useGiftStoreDependencies()

  const [selectedOrder, setSelectedOrder] = useState(currentStoreGift?.command?.id ?? '')
  const [availableCommands, setAvailableCommands] = useState<Command[]>([])

  const getClientCommands = commandClient.getClientCommands

  const orderSelected = useCallback((order: string) => {
    setSelectedOrder(order)
  }, [])

  useEffect(() => {
    if (selectedClientId <= 0) {
      return
    }
    getClientCommands(selectedClientId).then(setAvailableCommands)
  }, [getClientCommands, selectedClientId])

  return {
    // order selection
    selectedOrder,
    orderSelected,
    availableCommands
  }
}
