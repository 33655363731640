import React from 'react'
import InputWithValidation from './InputWithValidation'
import { Button, FormControl, Image, BoxProps, useDisclosure } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import FileUploadModal from '../views/modals/FileUploadModal'
import { UploadFile } from 'antd'
import { File } from './FileUpload'
import { ConfigurationHelper } from '../helpers/configurationHelper'

export type ConfigureGiftProps = BoxProps & {
  pTitle: string
  onTitleChange: (title: string) => Promise<void>
  onTitleBlur: (title: string) => Promise<void>
  isValidTitle: boolean
  titleError?: string

  description: string
  onDescriptionChange: (description: string) => Promise<void>
  onDescriptionBlur: (description: string) => Promise<void>
  isValidDescription: boolean
  descriptionError?: string

  pictureContent: string
  isValidPicture: boolean
  handlePictureUpload: (content: UploadFile<File>) => void

  loading: boolean
}

export function ConfigureGift(props: ConfigureGiftProps): React.JSX.Element {
  const intl = useIntl()
  const {
    isOpen: isFileUploadOpen,
    onOpen: onFileUploadOpen,
    onClose: onFileUploadClose
  } = useDisclosure()
  const { validMimeTypes, maxImageWeightInMb } = ConfigurationHelper.readConfiguration()

  return (
    <>
      <InputWithValidation
        isValid={props.isValidTitle}
        error={props.titleError}
        placeHolder={intl.formatMessage({
          description: 'configureLinkGiftTitlePlaceHolder',
          defaultMessage: "De quoi s'agit-il ?"
        })}
        defaultValue={props.pTitle}
        onChange={props.onTitleChange}
        onBlur={props.onTitleBlur}
        fontWeight='600'
        color='var(--var-text-color)'
        bgColor='rgba(57, 119, 121, 0.04)'
        mb='16px'
        _hover={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)'
        }}
        _focus={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)',
          outline: '0px'
        }}
      />

      <InputWithValidation
        placeHolder={intl.formatMessage({
          description: 'configureLinkGiftDescriptionPlaceHolder',
          defaultMessage: 'Saisissez une petite description de votre cadeau'
        })}
        defaultValue={props.description}
        isValid={props.isValidDescription}
        error={props.descriptionError}
        onChange={props.onDescriptionChange}
        onBlur={props.onDescriptionBlur}
        fontWeight='600'
        color='var(--var-text-color)'
        bgColor='rgba(57, 119, 121, 0.04)'
        _hover={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)'
        }}
        _focus={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)',
          outline: '0px'
        }}
      />

      <FormControl
        display='flex'
        w='554px'
        h='200px'
        alignSelf='center'
        borderRadius='8px'
        mb='16px'
        isInvalid={props.isValidPicture}
      >
        {!props.loading && props.pictureContent.length > 0 ? (
          <Image src={props.pictureContent} objectFit='cover' onClick={onFileUploadOpen} />
        ) : (
          <Button
            onClick={onFileUploadOpen}
            alignSelf='stretch'
            flexGrow='1'
            h='full'
            fontFamily='Nunito Sans'
            fontWeight='600'
            lineHeight='125%'
            color='var(--var-text-color)'
            opacity='0.4000000059604645'
            borderRadius='6px'
            bgColor='rgba(57, 119, 121, 0.04)'
          >
            <FormattedMessage
              description='configureLinkGiftChoosePictureMessage'
              defaultMessage='Choisir une photo'
            />
          </Button>
        )}
      </FormControl>
      <FileUploadModal
        isOpen={isFileUploadOpen}
        onClose={onFileUploadClose}
        onUpload={props.handlePictureUpload}
        maxWeightInMB={maxImageWeightInMb}
        validMimeTypes={validMimeTypes || []}
      />
    </>
  )
}
