import useUserStore from './userStore'
import HttpError from './errors/HttpError'

const apiUrl = process.env.REACT_APP_API_URL
export function getHeaders() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${useUserStore.getState().accessToken}`
  }
}

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export async function fetchApi<T>(route: string, method: Method, body?: string): Promise<T> {
  const response = await fetch(`${apiUrl}/${route}`, {
    method,
    headers: getHeaders(),
    body
  })
  if (!response.ok) throw new HttpError(response.status, await response.text())

  return response.json()
}

export async function fetchFormEncoded<T, U extends Record<string, string | Blob>>(
  route: string,
  method: Method,
  body: U
): Promise<T> {
  const formData = new FormData()

  if (body !== null) {
    for (let prop in body) {
      if (body[prop] === undefined) {
        continue
      }

      formData.append(prop, body[prop])
    }
  }

  const response = await fetch(`${apiUrl}/${route}`, {
    method,
    headers: {
      Authorization: `Bearer ${useUserStore.getState().accessToken}`
    },
    body: formData
  })
  if (!response.ok) throw new HttpError(response.status, await response.text())

  return response.json()
}
