import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import SmallText from '../components/SmallText'
import { getSteps } from './breadcrumbStepsHelper'
import Page from '../components/Page'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import { Flex } from '@chakra-ui/react'
import InputWithValidation from '../components/InputWithValidation'
import useProjectStore from '../stores/projectStore'

import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useChooseOtherOccasionUseCase from '../useCases/chooseOtherOccasionUseCase'
export default function OtherOccasion(): React.JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const {
    // other occasion
    occasion,
    hasOccasionChanged,
    updateOccasion,
    validateOccasion,
    changeOccasion,

    isOccasionLoadOrUpdateKO,
    // navigation
    currentProjectId,
    currentStoreGift
  } = useChooseOtherOccasionUseCase(giftClient)

  const steps: Step[] = getSteps('Évènement', currentStoreGift?.reachedStep)
  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='selectOccasionTitleLabel'
          defaultMessage='Que fêtez vous ?'
        />
      </Header2>
      <SmallText mb='4px'>
        <FormattedMessage
          description='otherOccasionSubtitle'
          defaultMessage="Saisissez l'occasion dans le champ ci-dessous."
        />
      </SmallText>

      <InputWithValidation
        isValid={!hasOccasionChanged || validateOccasion(occasion).success}
        error={hasOccasionChanged ? validateOccasion(occasion)?.error || undefined : undefined}
        maxLength={20}
        placeHolder={intl.formatMessage({
          description: 'configureLinkGiftLinkPlaceHolder',
          defaultMessage: "De quoi s'agit-il ?"
        })}
        defaultValue={occasion}
        onChange={changeOccasion}
        onBlur={updateOccasion}
        fontWeight='600'
        color='var(--var-text-color)'
        bgColor='rgba(57, 119, 121, 0.04)'
        _hover={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)'
        }}
        _focus={{
          borderRadius: '6px',
          border: '1.5px solid rgba(23, 68, 77, 0.50)',
          background: 'rgba(57, 119, 121, 0.04)',
          outline: '0px'
        }}
      />

      <Flex w='full' justifyContent='center' gap='24px' mt='80px' mb='24px'>
        <SecondaryButton
          as={DomLink}
          variant='outline'
          onClick={e => {
            e.preventDefault()
            if (currentStoreGift) {
              navigate(
                `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/${
                  currentStoreGift.event?.sendingMode === 'MULTIPLE'
                    ? 'receiversDetails'
                    : 'receiversUnique'
                }`
              )
            }
          }}
        >
          <FormattedMessage description='generalReturn' defaultMessage='Retour' />
        </SecondaryButton>
        <PrimaryButton
          isDisabled={isOccasionLoadOrUpdateKO()}
          className={isOccasionLoadOrUpdateKO() ? 'disabled-primary-btn' : ''}
          onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault()
            if (currentStoreGift) {
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/eventDate`)
            }
          }}
        >
          <FormattedMessage description='generalNext' defaultMessage='Suivant' />
        </PrimaryButton>
      </Flex>
    </Page>
  )
}
