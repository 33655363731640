import React, { useState } from 'react'
import Header2 from '../components/Header2'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Heading
} from '@chakra-ui/react'
import { useGiftStoreDependencies } from './hooks/useGiftStoreDependencies'
import useProjectStore from '../stores/projectStore'
import SecondaryButton from '../components/SecondaryButton'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/PrimaryButton'
import LayoutsStack from '../components/LayoutsStack'

import '../views/HideMainNavbar.css'

export type ConfirmTemplateProps = {
  isOpen: boolean
  onClose: () => void
}

export default function ConfirmTemplate({
  isOpen,
  onClose
}: ConfirmTemplateProps): React.JSX.Element {
  const navigate = useNavigate()
  const intl = useIntl()

  const [updateTemplateError, setUpdateTemplateError] = useState(null as string | null)

  const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

  const currentStoreTemplateId = useProjectStore(state => state.currentTemplateId)
  const currentTemplateId =
    currentStoreTemplateId > 0 ? currentStoreTemplateId : currentStoreGift?.template?.id ?? -1

  const currentTemplate = useProjectStore(state =>
    state.templates.find(template => template.id === currentTemplateId)
  )
  const layouts = useProjectStore(
    state => state.templates.find(template => template.id === currentTemplateId)?.layouts
  )
  const updateGiftTemplate = useProjectStore(state => state.updateGiftTemplate)

  const cancelRef = React.useRef({} as HTMLButtonElement)

  if (isOpen && currentStoreGift === undefined) {
    throw new Error('missing current gift!')
  }
  if (isOpen && (currentTemplateId === -1 || currentTemplate === undefined)) {
    throw new Error('invalid template selected!')
  }
  if (isOpen && layouts === undefined) {
    throw new Error(`cannot find layouts for template ${currentTemplateId}!`)
  }

  const updateTemplateForGift = async () => {
    if ((currentStoreGift?.template?.id ?? -1) === currentTemplateId) {
      return true
    }
    if (currentStoreGift && currentStoreGift.id) {
      return await updateGiftTemplate(currentProjectId, currentStoreGift.id, currentTemplateId)
        .then(() => true)
        .catch(() => {
          const errorMessage = intl.formatMessage({
            description: 'confirmTemplateUpdateErrorMessage',
            defaultMessage: "Une erreur s'est produite lors de la mise à jour du template"
          })
          setUpdateTemplateError(errorMessage)
          return false
        })
    } else {
      return false
    }
  }

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose} size={'5xl'}>
      <AlertDialogOverlay>
        <AlertDialogContent px='56px'>
          <AlertDialogHeader>
            <Header2
              alignSelf='center'
              textAlign='center'
              margin='auto 0 12px 0'
              fontSize={[20, 22, 24, 38]}
              lineHeight='41px'
            >
              <FormattedMessage
                description='templateSelectionTitleLabel'
                defaultMessage='Modèle {modelName}'
                values={{ modelName: currentTemplate?.name }}
              />
            </Header2>
            <Heading
              as='h3'
              fontSize={[14, null, null, 16]}
              fontWeight='normal'
              lineHeight='20px'
              mb='24px'
              textAlign='center'
            >
              <FormattedMessage
                description='confirmTemplateSubtitle'
                defaultMessage='Vous pourrez choisir la mise en page (nombre de photo, type de texte) une fois que vous aurez sélectionné votre modèle'
              />
            </Heading>
          </AlertDialogHeader>
          <AlertDialogBody>
            <style>{`.layout-card { 
						width: 320px; height: 420px;
					}`}</style>

            {currentStoreGift && currentTemplate && layouts && currentStoreGift && (
              <LayoutsStack
                gift={currentStoreGift}
                template={currentTemplate}
                layouts={layouts}
                sender={currentStoreGift.sender}
                excludedRole='INTRODUCTION'
                cardWidth='full'
                w='full'
              />
            )}

            {updateTemplateError && <Flex color='red.500'>{updateTemplateError}</Flex>}

            <Flex w='full' justifyContent='center' gap='24px' mt='80px' mb='24px'></Flex>
          </AlertDialogBody>

          {currentStoreGift && (
            <AlertDialogFooter justifyContent={'center'} gap={'24px'}>
              <SecondaryButton
                as={DomLink}
                variant='outline'
                onClick={e => {
                  e.preventDefault()
                  onClose()
                  navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/eventDate`)
                }}
                ref={cancelRef}
              >
                <FormattedMessage description='generalReturn' defaultMessage='Retour' />
              </SecondaryButton>
              <PrimaryButton
                onClick={async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
                  e.preventDefault()
                  const success = await updateTemplateForGift()
                  if (!success) {
                    return
                  }
                  navigate(
                    `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/template/${currentTemplateId}/layout`
                  )
                }}
              >
                <FormattedMessage description='generalSelect' defaultMessage='Sélectionner' />
              </PrimaryButton>
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
