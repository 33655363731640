import { ButtonGroup, Button, Flex, Heading } from '@chakra-ui/react'
import LoginComponent from '../components/LoginComponent'
import React from 'react'

export default function Login(): React.JSX.Element {
  return (
    <Flex
      w='100vw'
      h='100vh'
      justifyContent='center'
      alignItems='center'
      direction='column'
      gap='8'
      position='absolute'
      top='0'
      left='0'
    >
      <Heading>Présent | Front-office</Heading>
      <ButtonGroup>
        <LoginComponent>
          <Button colorScheme='teal'>Se connecter</Button>
        </LoginComponent>
      </ButtonGroup>
    </Flex>
  )
}
