import { useIntl } from 'react-intl'
import { useCsvFileUpload } from '../components/hooks/useCsvFileUpload'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import { ConfigurationHelper } from '../helpers/configurationHelper'
import { useCallback, useMemo, useState } from 'react'
import GiftClient from '../domain/adapters/secondary/giftClient'
import { Receiver } from '../domain/receiver'

export default function useChangeReceiversUseCase(giftClient: GiftClient) {
  const intl = useIntl()

  const { currentStoreGift, currentProjectId } = useGiftStoreDependencies()
  const { maxCsvFileWeightInMb } = ConfigurationHelper.readConfiguration()
  const [isCsvLoading, setIsCsvLoading] = useState(false)
  const [isCsvSuccess, setIsCsvSuccess] = useState(false)

  const changeGiftReceivers = giftClient.currentGiftReceiversChanged
  const receiversChanged = useCallback(
    (giftId: number, receivers: Receiver[]) => {
      changeGiftReceivers(giftId, receivers)
    },
    [changeGiftReceivers]
  )

  const updateIsCsvLoading = useCallback((isLoading: boolean) => {
    setIsCsvLoading(isLoading)
  }, [])

  const updateIsCsvSuccess = useCallback((isSuccess: boolean) => {
    setIsCsvSuccess(isSuccess)
  }, [])

  const hasReceivers = useMemo(
    () => (currentStoreGift?.event?.receivers?.length ?? 0) > 0,
    [currentStoreGift?.event?.receivers?.length]
  )

  const sizeErrorMessage = intl.formatMessage(
    {
      description: 'receiversSizeErrorMessage',
      defaultMessage: 'Votre fichier ne peut pas excéder {maxSizeMo}mo'
    },
    { maxSizeMo: maxCsvFileWeightInMb }
  )

  const formatErrorMessage = intl.formatMessage({
    description: 'receiversFormatErrorMessage',
    defaultMessage: 'Le format du fichier n’est pas reconnu, veuillez importer un fichier CSV'
  })

  const unknownErrorMessage = intl.formatMessage({
    description: 'generalUnknownErrorMessage',
    defaultMessage: "Une erreur s'est produite."
  })

  const {
    csvValidationError,
    csvUploadError,
    csvFileName,
    handlePreview,
    beforeUpload,
    handleChange,
    chooseFileLabel,
    fileList
  } = useCsvFileUpload({
    initialValue: '',
    maxWeightInMB: maxCsvFileWeightInMb * 1024,
    validMimeTypes: ['text/csv'],
    chooseFileLabel: intl.formatMessage({
      description: 'fileUploadModalImportBtn',
      defaultMessage: 'Importer'
    }),
    sizeErrorMessage,
    formatErrorMessage,
    unknownErrorMessage,
    setIsLoading: updateIsCsvLoading,
    setIsSuccess: updateIsCsvSuccess,
    receiversChanged,
    currentStoreGift,
    giftFileNameById: giftClient.giftPictureFileNameById
  })

  const csvError = useCallback(
    (): string | undefined => (csvValidationError ?? undefined) || (csvUploadError ?? undefined),
    [csvValidationError, csvUploadError]
  )

  return {
    // csv upload
    csvValidationError,
    csvUploadError,
    csvFileName,
    handleCsvPreview: handlePreview,
    handleCsvFormatValidation: beforeUpload,
    handleCsvFileChange: handleChange,
    csvChooseFileLabel: chooseFileLabel,
    csvFileList: fileList,
    isCsvLoading,
    isCsvSuccess,
    maxCsvFileWeightInMb,
    updateIsCsvSuccess,
    updateIsCsvLoading,
    // validation
    csvError,
    // navigation
    currentProjectId,
    currentStoreGift,
    // open project mode
    hasReceivers
  }
}
