import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import { ConfigProvider as AntdProvider } from 'antd'
import frFR from 'antd/locale/fr_FR'
import { IntlProvider } from 'react-intl'
import { FirebaseOptions, initializeApp } from 'firebase/app'

import { LOCALES, MESSAGES } from './i18n/messageService'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const selectedLocale =
  Object.values(LOCALES).indexOf(navigator.language) >= 0 ? navigator.language : LOCALES.FRENCH

const i18nMessages = MESSAGES[selectedLocale]

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_GA_API_KEY,
  appId: process.env.REACT_APP_GA_APP_ID,
  authDomain: process.env.REACT_APP_GA_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GA_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_GA_MESSAGE_SENDER_ID,
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID
}

initializeApp(firebaseConfig)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }}
    >
      <HashRouter>
        <AntdProvider locale={frFR}>
          <ChakraProvider>
            <IntlProvider
              messages={i18nMessages}
              locale={selectedLocale}
              defaultLocale={LOCALES.FRENCH}
            >
              <App />
            </IntlProvider>
          </ChakraProvider>
        </AntdProvider>
      </HashRouter>
    </Auth0Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
