import { Layout } from './layout'
import PathHelper from '../helpers/pathHelper'

export interface Template {
  id: number
  name: string
  picturePath: string
  titleFont: string
  bodyFont: string
  titleSizes: string[]
  smallTitleSizes: string[]
  subtitleSizes: string[]
  bodySizes: string[]
  titleLineHeights: string[]
  smallTitleLineHeights: string[]
  subtitleLineHeights: string[]
  bodyLineHeights: string[]
  callToActionBackgroundColor: string
  textColor: string
  darkTextColor: string
  holdImage: string | undefined
  knotImage: string | undefined
  solidBackground1: string | undefined
  solidBackground2: string | undefined
  solidBackground3: string | undefined
  signatureBackgroundImage: string | undefined

  layouts: Layout[]
  createdAt: Date
  updatedAt: Date
}

export function getTextColor(template: Template, usesDarkMode: boolean) {
  return usesDarkMode ? template.darkTextColor : template.darkTextColor
}

export function getFormattedName(template: Template | undefined) {
  return template?.name
    ?.replaceAll('é', 'e')
    .replaceAll('è', 'e')
    .replaceAll(/ +/g, '_')
    .toLocaleLowerCase()
}
export function getRealPicturePath(template: Template, usesDarkMode: boolean) {
  const prefix = usesDarkMode ? '_dark' : '_light'
  return PathHelper.appendBeforeExtension(template.picturePath, prefix)
}
