import { BoxProps, Flex } from '@chakra-ui/react'

export type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs'
export type Style = 'style1' | 'style2' | 'style3'

export type ImgContainerProps = BoxProps & {
  containerSize: Size
  containerStyle: Style
}

type ContainerInfos = Pick<CSSProperties, 'padding'> & Pick<CSSProperties, 'borderRadius'>

export const sizeSelector: Record<Size, Pick<CSSProperties, 'width'>> = {
  xl: { width: 240 },
  lg: { width: 175 },
  md: { width: 160 },
  sm: { width: 150 },
  xs: { width: 140 },
  '2xs': { width: 130 }
}

export default function ImgContainer({
  containerSize,
  containerStyle,
  children,
  ...boxProps
}: ImgContainerProps): React.JSX.Element {
  const xlStyleSelector = (style: Style) =>
    ({
      style1: {
        padding: 8,
        borderRadius: 8
      },
      style2: {
        padding: '4px 4px 24px 4px',
        borderRadius: 4
      },
      style3: {
        padding: 0,
        borderRadius: 12
      }
    })[style]
  const nonStyleXlSelector = (style: Style) =>
    ({
      style1: {
        padding: 4,
        borderRadius: 6
      },
      style2: {
        padding: '1px 1px 12px 1px',
        borderRadius: 2
      },
      style3: {
        padding: 0,
        borderRadius: 8
      }
    })[style]

  const styleSelector: Record<Size, (style: Style) => ContainerInfos> = {
    xl: xlStyleSelector,
    lg: nonStyleXlSelector,
    md: nonStyleXlSelector,
    sm: nonStyleXlSelector,
    xs: nonStyleXlSelector,
    '2xs': nonStyleXlSelector
  }

  return (
    <Flex
      w={sizeSelector[containerSize].width}
      h={sizeSelector[containerSize].width}
      borderRadius={styleSelector[containerSize](containerStyle).borderRadius}
      p={styleSelector[containerSize](containerStyle).padding}
      bgColor='transparent'
      boxShadow='0px 6px 20px -2px rgba(0, 0, 0, 0)'
      alignItems='flex-start'
      gap={8}
      flexShrink={0}
      className={'img-container'}
      {...boxProps}
    >
      <style>{`
				.anim-multiple-img:nth-child(odd) {
					margin-top: -116px;
				}
				
				.anim-multiple-img:nth-child(even) {
					margin-top: -58px;
				}
				.item-container.with-multiple-image .item-text1 {
					position: relative;
					top: -20%;
				}
				
				.item-container.with-single-image .img-container {
					width: 100%;
    				height: calc(100% - 250px);
				}
				`}</style>
      {children}
    </Flex>
  )
}
