import GiftClient from '../domain/adapters/secondary/giftClient'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import { useCallback, useState } from 'react'
import { getGiftDate } from '../domain/gift'

export default function useChooseOpeningGameUseCase(giftClient: GiftClient) {
  const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

  const updateGiftOpeningMode = giftClient.updateGiftOpeningMode

  const computeQuestion = () =>
    currentStoreGift?.openingGame?.question.startsWith('__blank__')
      ? ''
      : currentStoreGift?.openingGame?.question

  const [question, setQuestion] = useState(computeQuestion())

  const questionChanged = useCallback((question: string) => {
    setQuestion(question)
  }, [])

  const updateOpeningGame = useCallback(async () => {
    if (question === undefined) return false

    const hasAnswers = (currentStoreGift?.openingGame?.answers?.length ?? 0) > 0

    try {
      if (currentStoreGift && currentStoreGift.id && currentStoreGift.openingMode) {
        await updateGiftOpeningMode(
          currentProjectId,
          currentStoreGift.id,
          currentStoreGift.openingMode,
          getGiftDate(currentStoreGift.openingDate),
          {
            question,
            answers: hasAnswers ? currentStoreGift?.openingGame?.answers ?? [] : ['blank']
          }
        )
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }, [currentProjectId, currentStoreGift, question, updateGiftOpeningMode])

  return {
    question,
    questionChanged,
    currentProjectId,
    currentStoreGift,
    updateOpeningGame
  }
}
