/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  ButtonGroup,
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../components/PrimaryButton'
import {
  HeartOutlined,
  EuroOutlined,
  DownOutlined,
  GiftOutlined,
  FilterOutlined,
  MoreOutlined,
  ThunderboltOutlined,
  EditOutlined,
  StarOutlined,
  BookOutlined,
  LineChartOutlined,
  CloudOutlined
} from '@ant-design/icons'
import { Icon } from '@chakra-ui/icons'

function FilterTag(props: {
  children: React.JSX.Element
  icon: any
  icon2?: any
}): React.JSX.Element {
  return (
    <Tag
      size='lg'
      variant='outline'
      p='12px 16px'
      borderWidth='1px'
      borderColor='#CCD1D5'
      borderRadius='8px'
      cursor='pointer'
    >
      <TagLeftIcon as={props.icon} color='#1F3043' />
      <TagLabel
        fontFamily='Nunito Sans'
        fontSize='12px'
        fontWeight='500'
        lineHeight='12px'
        color='#17444D'
      >
        {props.children}
      </TagLabel>
      {props.icon2 && <TagRightIcon as={props.icon2} color='#1F3043' />}
    </Tag>
  )
}

function Banner(): React.JSX.Element {
  return (
    <Flex
      borderRadius='12px'
      backgroundImage='linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 54.5%, rgba(0, 0, 0, 0.2) 100%);'
      minH='300px'
      w='100%'
      flexDir='column'
      gap='16px'
      p='16px 20px'
    >
      <Heading
        mt='auto'
        fontFamily='DM Serif Display'
        fontSize='38px'
        fontWeight='400'
        lineHeight='41.8px'
      >
        Titre
      </Heading>
      <Text fontFamily='Nunito Sans' fontSize='18px' fontWeight='600' lineHeight='28px'>
        Description
      </Text>
    </Flex>
  )
}

function CategoryFilter(props: {
  children: React.JSX.Element
  icon: any
  isSelected?: boolean
}): React.JSX.Element {
  return (
    <Flex
      p='8px 12px'
      flexDir='column'
      gap='8px'
      alignItems='center'
      backgroundColor={props.isSelected ? '#39777933' : 'transparent'}
      borderRadius='8px'
      cursor='pointer'
    >
      <Icon as={props.icon} color='#17444D' w='24px' h='24px' />
      <Text
        color='#17444D'
        fontFamily='Nunito Sans'
        fontSize='16px'
        fontWeight='400'
        lineHeight='20.8px'
      >
        {props.children}
      </Text>
    </Flex>
  )
}

export default function Catalog(): React.JSX.Element {
  return (
    <Flex gap='40px' flexDirection='column' padding='80px' h='100%'>
      <style>{`#root div.main-navbar { display: flex; }`}</style>
      <Flex justifyContent='space-between' w='100%'>
        <Heading fontFamily='DM Serif Display' fontWeight='400'>
          <FormattedMessage description='catalogPageTitle' defaultMessage='Catalogue cadeaux' />
        </Heading>
        <ButtonGroup>
          <PrimaryButton size='lg'>
            <a href='mailto:catalogue@lecadeaupresent.com?subject=Question%20plateforme%20SaaS&body=Bonjour%20Marie-Ang%C3%A9lique%2C%0D%0A%0D%0AVoici%20ma%20demande%20%3A%0D%0A%0D%0AMon%20pr%C3%A9nom%20-%20Nom%20%3A%0D%0AMon%20entreprise%20%3A%0D%0AMa%20question%20%3A%0D%0A%0D%0ABien%20%C3%A0%20vous%2C%0D%0A'>
              <FormattedMessage
                description='catalogPageContactStaffButton'
                defaultMessage="Contacter l'équipe"
              />
            </a>
          </PrimaryButton>
        </ButtonGroup>
      </Flex>
      <Flex gap='8px'>
        <FilterTag icon={HeartOutlined}>
          <Flex gap='8px'>
            <FormattedMessage
              description='catalogPageFilterTagFav'
              defaultMessage='Favoris'
            ></FormattedMessage>
            <span>•</span>
            <span style={{ color: '#D96E34' }}>0</span>
          </Flex>
        </FilterTag>
        <FilterTag icon={EuroOutlined} icon2={DownOutlined}>
          <Flex gap='8px'>
            <FormattedMessage
              description='catalogPageFilterPrice'
              defaultMessage='Prix'
            ></FormattedMessage>
            <span>•</span>
            <span style={{ color: '#D96E34' }}>
              <FormattedMessage
                description='catalogPageFilterPrice2'
                defaultMessage='Tous'
              ></FormattedMessage>
            </span>
          </Flex>
        </FilterTag>
        <FilterTag icon={GiftOutlined} icon2={DownOutlined}>
          <Flex gap='8px'>
            <FormattedMessage
              description='catalogPageFilterGift'
              defaultMessage='Type de cadeau'
            ></FormattedMessage>
            <span>•</span>
            <span style={{ color: '#D96E34' }}>
              <FormattedMessage
                description='catalogPageFilterPrice2'
                defaultMessage='Tous'
              ></FormattedMessage>
            </span>
          </Flex>
        </FilterTag>
        <FilterTag icon={FilterOutlined} icon2={DownOutlined}>
          <Flex gap='8px'>
            <FormattedMessage
              description='catalogPageFilterAdvanced'
              defaultMessage='Filres avancés'
            ></FormattedMessage>
            <span>•</span>
            <span style={{ color: '#D96E34' }}>
              <FormattedMessage
                description='catalogPageFilterPrice2'
                defaultMessage='Tous'
              ></FormattedMessage>
            </span>
          </Flex>
        </FilterTag>
      </Flex>
      <Banner />
      <Flex gap='12px'>
        <CategoryFilter icon={MoreOutlined} isSelected={true}>
          <FormattedMessage description='catalogPageFilterCategoryAll' defaultMessage='Tout' />
        </CategoryFilter>
        <CategoryFilter icon={ThunderboltOutlined}>
          <>
            <FormattedMessage
              description='catalogPageFilterCategory2'
              defaultMessage='Expériences'
            />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory2-2'
              defaultMessage='& Séjours'
            />
          </>
        </CategoryFilter>
        <CategoryFilter icon={EditOutlined}>
          <>
            <FormattedMessage
              description='catalogPageFilterCategory8'
              defaultMessage='Savoir-faire'
            />
            <br />
            <FormattedMessage description='catalogPageFilterCategory8-8' defaultMessage='& Arts' />
          </>
        </CategoryFilter>
        <CategoryFilter icon={HeartOutlined}>
          <>
            <FormattedMessage
              description='catalogPageFilterCategory3'
              defaultMessage='Bien-être physique'
            />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory3-3'
              defaultMessage='& Santé mentale'
            />
          </>
        </CategoryFilter>
        <CategoryFilter icon={StarOutlined}>
          <>
            <FormattedMessage description='catalogPageFilterCategory4' defaultMessage='Talents' />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory4-4'
              defaultMessage='& Créativité'
            />
          </>
        </CategoryFilter>
        <CategoryFilter icon={BookOutlined}>
          <>
            <FormattedMessage
              description='catalogPageFilterCategory5'
              defaultMessage='Initiations'
            />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory5-5'
              defaultMessage='& Formations'
            />
          </>
        </CategoryFilter>
        <CategoryFilter icon={LineChartOutlined}>
          <>
            <FormattedMessage
              description='catalogPageFilterCategory6'
              defaultMessage='Accompagnement'
            />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory6-6'
              defaultMessage='moments de vie'
            />
          </>
        </CategoryFilter>
        <CategoryFilter icon={CloudOutlined}>
          <>
            <FormattedMessage description='catalogPageFilterCategory7' defaultMessage='Ecologie' />
            <br />
            <FormattedMessage
              description='catalogPageFilterCategory7-7'
              defaultMessage='& Climat'
            />
          </>
        </CategoryFilter>
      </Flex>
    </Flex>
  )
}
