import { z } from 'zod'
import { GiftInfosHelper } from '../../helpers/giftInfosHelper'
import { useIntl } from 'react-intl'
import { useCallback, useRef, useState } from 'react'
import { useGiftStoreDependencies } from './useGiftStoreDependencies'

export function useDescription(initialValue: string) {
  const intl = useIntl()
  const [description, setDescription] = useState(initialValue)
  const [descriptionLoading, setDescriptionLoading] = useState(false)
  const [descriptionUpdateError, setDescriptionUpdateError] = useState(null as string | null)

  const hasDescriptionChanged = useRef(false)

  const { currentStoreGift, currentProjectId, updateGiftInfo } = useGiftStoreDependencies()
  const currentGift = currentStoreGift ? GiftInfosHelper.makeGiftInfos(currentStoreGift) : undefined

  const validateDescription = useCallback(
    (desc: string) => {
      try {
        z.string().min(3).max(150).parse(desc)
        return { success: true, error: null }
      } catch (err) {
        const error = intl.formatMessage({
          description: 'configureLinkGiftInvalidDescriptionMessage',
          defaultMessage: 'Ce champ doit contenir entre 3 et 150 caractères.'
        })
        return { success: false, error }
      }
    },
    [intl]
  )

  const changeDescription = useCallback(async (desc: string) => {
    setDescription(desc)
    hasDescriptionChanged.current = true
  }, [])

  const updateDescription = useCallback(
    async (desc: string) => {
      if (!hasDescriptionChanged.current || desc === '') {
        return
      }
      try {
        if (currentGift) {
          setDescriptionLoading(true)
          const updatedGift = GiftInfosHelper.updateProperty(currentGift, 'description', desc)
          await updateGiftInfo(currentProjectId, updatedGift)
          hasDescriptionChanged.current = false
        }
      } catch (err) {
        hasDescriptionChanged.current = true
        setDescriptionUpdateError(
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {eventDesc}."
            },
            { eventDesc: 'la mise à jour de la description' }
          )
        )
      } finally {
        setDescriptionLoading(false)
      }
    },
    [intl, currentProjectId, currentGift, updateGiftInfo]
  )

  return {
    description,
    descriptionLoading,
    descriptionUpdateError,
    validateDescription,
    changeDescription,
    updateDescription,
    hasDescriptionChanged: hasDescriptionChanged.current
  }
}
