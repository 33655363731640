import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import GiftClient from '../domain/adapters/secondary/giftClient'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default function useSendGiftByEmailUseCase(giftClient: GiftClient) {
  const intl = useIntl()

  const { projectId, giftId } = useParams()
  const gift = giftClient.openedGift
  const sendByEmail = giftClient.sendGiftByEmail
  const getReceiver = giftClient.getReceiver
  const nSub = giftClient.currentReceiverTrackingId

  const [sendByEmailError, setSendByEmailError] = useState(null as string | null)
  const [emailSent, setEmailSent] = useState(false)

  if (Number.isNaN(projectId) || projectId === undefined) {
    throw new Error('Invalid projectId!')
  }

  if (Number.isNaN(giftId) || giftId === undefined) {
    throw new Error('Invalid giftId!')
  }

  if (gift === undefined) {
    throw new Error('Invalid gift')
  }

  if (gift.template === undefined || (gift.template?.layouts?.length ?? 0) === 0) {
    throw new Error('missing gift template or layouts')
  }

  const [projectIdNum, giftIdNum] = [Number(projectId), Number(giftId)]

  const sendGiftByEmail = async (email?: string) => {
    const success = await sendByEmail(projectIdNum, giftIdNum, email)
    setEmailSent(true)

    if (!success) {
      setSendByEmailError(
        intl.formatMessage({
          description: 'experiencePageSendByEmailError',
          defaultMessage: "Une erreur s'est produite lors de l'envoi de votre cadeau."
        })
      )
      return
    }
    setSendByEmailError(null)

    logEvent(getAnalytics(), 'front_b2b_retrieve_gift', {
      button_text: 'Récupérer mon cadeau',
      projectId: projectIdNum,
      giftId: giftIdNum
    })
  }

  return {
    // sending
    emailSent,
    sendByEmailError,
    sendGiftByEmail,
    getReceiver,
    openedGift: gift,

    // navigation
    projectId,
    giftId,
    nSub
  }
}
