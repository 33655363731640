import React, { useEffect } from 'react'
import ProjectBreadcrumb from '../components/ProjectBreadcrumb'
import { Flex, HStack } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import Header2 from '../components/Header2'
import SmallText from '../components/SmallText'
import { useIntl } from 'react-intl'

import { Link as DomLink, useNavigate } from 'react-router-dom'

import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import Page from '../components/Page'
import useProjectStore from '../stores/projectStore'
import LinkTypeComp from '../components/LinkType'
import { getSteps } from './breadcrumbStepsHelper'
import PresentAlert from '../components/PresentAlert'

import './HideMainNavbar.css'
import useChangeLinkTypeUseCase from '../useCases/changeLinkTypeUseCase'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'

export default function CreateGift(): React.JSX.Element {
  const intl = useIntl()
  const projectStore = useProjectStore()
  const navigate = useNavigate()

  const projectClient = useProjectStore(SlicesHelper.getProjectClient)
  const giftClient = useProjectStore(SlicesHelper.getGiftClient)

  const {
    currentProject,
    linkTypeSelected,
    isValidSelection,
    updateError,
    linkTypes,
    selectedLinkType
  } = useChangeLinkTypeUseCase(projectClient, giftClient)

  useEffect(() => {
    if (currentProject != null && selectedLinkType !== 'NONE' && currentProject.gifts.length > 0) {
      navigate(`/projects/${currentProject.id}/gifts/${currentProject.gifts[0].id}/creation`)
    }
  }, [currentProject, selectedLinkType, navigate])

  const urlForCurrentGiftType = () => {
    if (currentProject) {
      const currentGift = currentProject.gifts[0]
      const routes: Record<string, string> = {
        LINK: `/projects/${currentProject.id}/gifts/${currentGift.id}/linkInfos`,
        MSG: `/projects/${currentProject.id}/gifts/${currentGift.id}/receiversMode`,
        PDF: `/projects/${currentProject.id}/gifts/${currentGift.id}/pdfInfos`
      }
      if (selectedLinkType in routes) {
        return routes[selectedLinkType]
      }
      return `/projects/${projectStore.currentProjectId}/notFoundPage`
    }
  }

  const steps = getSteps('Cadeaux', currentProject?.gifts[0]?.reachedStep)

  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProject?.id?.toString() || ''}
        giftId={currentProject?.gifts[0]?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='createGiftTitle'
          defaultMessage='Que souhaitez vous offrir ?'
        />
      </Header2>
      <SmallText mb='44px'>
        <FormattedMessage
          description='createGiftSubtitle'
          defaultMessage='Appuyez sur le type de cadeaux que vous souhaitez chargé dans votre présent.'
        />
      </SmallText>
      <HStack gap='8px' mb='48px' justifyContent='center'>
        {linkTypes.map(lt =>
          LinkTypeComp(lt, selectedLinkType !== 'NONE', () => linkTypeSelected(lt))
        )}
      </HStack>
      <Flex w='full' justifyContent='center' gap='24px' mt='48px' mb='24px'>
        <SecondaryButton
          as={DomLink}
          onClick={e => {
            e.preventDefault()
            navigate(`/project/creation${currentProject ? '?id=' + currentProject.id : ''}`)
          }}
        >
          <FormattedMessage description='generalCancel' defaultMessage='Annuler' />
        </SecondaryButton>
        <PrimaryButton
          isDisabled={
            !isValidSelection ||
            !currentProject ||
            !currentProject.gifts ||
            currentProject.gifts.length === 0
          }
          onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault()
            const newRoute = urlForCurrentGiftType()
            if (newRoute) {
              navigate(newRoute)
            }
          }}
        >
          <FormattedMessage description='generalNext' defaultMessage='Suivant' />
        </PrimaryButton>
      </Flex>
      {updateError !== null ? (
        <PresentAlert
          status={'error'}
          title={intl.formatMessage({
            description: 'createLinkServerErrorTitle',
            defaultMessage: 'Erreur'
          })}
          description={updateError}
        />
      ) : null}
    </Page>
  )
}
