import { Heading } from '@chakra-ui/react'
import React from 'react'
import { TitleHeadingProps } from './TitleHeading'

export function SubtitleHeading({
  typedIndex,
  text,
  ...boxProps
}: TitleHeadingProps<HTMLElement>): React.JSX.Element {
  return (
    <Heading as='h3' key={`text-${typedIndex}`} {...boxProps}>
      {text}
    </Heading>
  )
}
