import React from 'react'
import { Heading, HeadingProps } from '@chakra-ui/react'

import './Header2.css'

export default function Header2(props: HeadingProps): React.JSX.Element {
  return (
    <Heading
      fontFamily='DM Serif Display'
      fontStyle='normal'
      fontWeight={400}
      fontSize={[12, 16, 28, 28, 34, 38]}
      lineHeight='110%'
      textAlign='left'
      color='var(--var-heading2-color)'
      flexGrow={0}
      alignSelf='stretch'
      flex='none'
      {...props}
    >
      {props.children}
    </Heading>
  )
}
