import { z } from 'zod'
import { GiftInfosHelper } from '../../helpers/giftInfosHelper'
import { useIntl } from 'react-intl'
import { useCallback, useRef, useState } from 'react'
import { useGiftStoreDependencies } from './useGiftStoreDependencies'

export function useTitle(initialValue: string) {
  const intl = useIntl()
  const [title, setTitle] = useState(initialValue)
  const [titleLoading, setTitleLoading] = useState(false)
  const [titleUpdateError, setTitleUpdateError] = useState(null as string | null)

  const hasTitleChanged = useRef(false)

  const { currentStoreGift, currentProjectId, updateGiftInfo } = useGiftStoreDependencies()
  const currentGift = currentStoreGift ? GiftInfosHelper.makeGiftInfos(currentStoreGift) : undefined

  const validateTitle = useCallback(
    (title: string) => {
      try {
        z.string().min(3).max(45).parse(title)
        return { success: true, error: null }
      } catch (err) {
        const error = intl.formatMessage({
          description: 'configureLinkGiftInvalidTitleMessage',
          defaultMessage: 'Ce champ doit contenir entre 3 et 45 caractères.'
        })
        return { success: false, error }
      }
    },
    [intl]
  )

  const changeTitle = useCallback(async (pTitle: string) => {
    setTitle(pTitle)
    hasTitleChanged.current = true
  }, [])

  const updateTitle = useCallback(
    async (title: string) => {
      if (!hasTitleChanged.current || title === '') {
        return
      }
      try {
        if (currentGift) {
          setTitleLoading(true)
          const updatedGift = GiftInfosHelper.updateProperty(currentGift, 'title', title)
          await updateGiftInfo(currentProjectId, updatedGift)
          hasTitleChanged.current = false
        }
      } catch (err) {
        hasTitleChanged.current = true
        setTitleUpdateError(
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {eventDesc}."
            },
            { eventDesc: 'la mise à jour du titre' }
          )
        )
      } finally {
        setTitleLoading(false)
      }
    },
    [intl, currentProjectId, currentGift, updateGiftInfo]
  )

  return {
    title,
    titleLoading,
    titleUpdateError,
    changeTitle,
    validateTitle,
    updateTitle,
    hasTitleChanged: hasTitleChanged.current
  }
}
