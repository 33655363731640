import GiftClient from '../domain/adapters/secondary/giftClient'
import { useIntl } from 'react-intl'
import { useGiftStoreDependencies } from '../components/hooks/useGiftStoreDependencies'
import { useCallback, useState } from 'react'
import { getGiftDate } from '../domain/gift'
import { View } from 'react-calendar/dist/esm/shared/types'
import { DateHelper } from '../helpers/dateHelper'

export default function useChooseOpeningDateUseCase(giftClient: GiftClient) {
  const intl = useIntl()

  const { currentProjectId, currentStoreGift } = useGiftStoreDependencies()

  const updateGiftOpeningMode = giftClient.updateGiftOpeningMode

  const today = new Date()
  const [updateError, setUpdateError] = useState(null as string | null)

  const [date, setDate] = useState(getGiftDate(currentStoreGift?.openingDate) ?? today)
  const [hasDateChanged, setHasDateChanged] = useState(false)
  const [activeStartDate, setActiveStartDate] = useState(today as Date | null)

  const dateChanged = useCallback((date: Date) => {
    setDate(date)
    setHasDateChanged(true)
  }, [])

  const activeStartDateChanged = useCallback((date: Date) => {
    setActiveStartDate(date)
  }, [])

  const weekDays = [
    intl.formatMessage({
      description: 'defaultWeekdaySun',
      defaultMessage: 'DIM'
    }),
    intl.formatMessage({
      description: 'defaultWeekdayMon',
      defaultMessage: 'LUN'
    }),
    intl.formatMessage({
      description: 'defaultWeekdayTue',
      defaultMessage: 'MAR'
    }),
    intl.formatMessage({
      description: 'defaultWeekdayWed',
      defaultMessage: 'MER'
    }),
    intl.formatMessage({
      description: 'defaultWeekdayThu',
      defaultMessage: 'JEU'
    }),
    intl.formatMessage({
      description: 'defaultWeekdayFri',
      defaultMessage: 'VEN'
    }),
    intl.formatMessage({
      description: 'defaultWeekdaySat',
      defaultMessage: 'SAM'
    })
  ]

  const tileClassName = ({ date: tileDate, view }: { date: Date; view: View }) => {
    if (view === 'month') {
      let className = ''
      if (DateHelper.isToday(tileDate)) {
        className = 'calendar-tile-today'
      }

      if (DateHelper.isSameDay(date, tileDate)) {
        className += (className.length > 0 ? ' ' : '') + 'calendar-tile-selected'
      }

      if (DateHelper.isBeforeToday(tileDate)) {
        className += (className.length > 0 ? ' ' : '') + 'calendar-tile-disabled'
      }

      if (!DateHelper.isSameMonthAs(tileDate, activeStartDate)) {
        className += (className.length > 0 ? ' ' : '') + 'calendar-tile-diffMonth'
      }

      return 'calendar-tile' + (className.length > 0 ? ' ' : '') + className
    }
  }

  const disableBeforeToday = ({ date }: { date: Date; view: View }) => {
    return DateHelper.isBeforeToday(date)
  }

  // first parameter was locale
  const formatWeekday = (_: string | undefined, date: Date) => {
    return weekDays[date.getDay()][0]
  }

  const updateOpeningDate = useCallback(async () => {
    try {
      if (currentStoreGift && currentStoreGift.id && currentStoreGift.openingMode) {
        await updateGiftOpeningMode(
          currentProjectId,
          currentStoreGift.id,
          currentStoreGift.openingMode,
          date,
          currentStoreGift.openingGame
        )
      }

      setUpdateError(null)
      return true
    } catch (err) {
      setUpdateError(
        (err as Error)?.message ??
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {openingDesc}."
            },
            { openingDesc: 'la mise à jour de la date' }
          )
      )
      return false
    }
  }, [currentProjectId, currentStoreGift, date, intl, updateGiftOpeningMode])

  return {
    currentProjectId,
    currentStoreGift,

    disableBeforeToday,
    formatWeekday,
    tileClassName,

    hasDateChanged,
    updateOpeningDate,

    updateError,

    date,
    dateChanged,
    activeStartDateChanged
  }
}
