import { StateCreator } from 'zustand'
import { ProjectStoreState } from '../../projectStore'
import TemplateClient from '../../../domain/adapters/secondary/templateClient'
import { fetchApi } from '../../storeHelper'
import { Template } from '../../../domain/template'
import { LayoutPack } from '../../../domain/layoutPack'
import { orderLayoutsByRole } from '../../../domain/layout'

export const createTemplateSlice: StateCreator<
  ProjectStoreState,
  [['zustand/immer', never]],
  [],
  TemplateClient
> = set => ({
  layoutPacks: [],
  templates: [],
  currentTemplateId: -1,

  getTemplates: async () => {
    // we retrieve templates with only their Layout of kind TEMPLATE
    // as the route is gift free, we do not want gift related data.
    const templates = await fetchApi<Template[]>('templates?kind=B2C', 'GET')
    set(state => {
      state.templates = templates
    })
    return templates
  },
  chooseTemplate: async (templateId: number) => {
    set(state => {
      state.currentTemplateId = templateId
    })
  },
  getLayoutPacks: async (): Promise<LayoutPack[]> => {
    const layoutPacks = await fetchApi<LayoutPack[]>('layoutPacks', 'GET')

    const layoutPacksWithOrderedLayouts = layoutPacks
      .filter(lp => lp.name !== 'PresentGreen')
      .map(layoutPack => ({
        ...layoutPack,
        layouts: orderLayoutsByRole(layoutPack.layouts)
      }))

    set(state => {
      state.layoutPacks = layoutPacksWithOrderedLayouts
    })
    return layoutPacksWithOrderedLayouts
  },
  applyLayoutPackToGift: async (
    projectId: number,
    giftId: number,
    layoutPackId: number
  ): Promise<{
    success: boolean
  }> => {
    return await fetchApi<{
      success: boolean
    }>(
      `projects/${projectId}/gifts/${giftId}/applyLayoutPack`,
      'PUT',
      JSON.stringify({ layoutPackId })
    )
  }
})
