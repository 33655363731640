import { BoxProps, ComponentWithAs, HStack, IconProps, Tag, TagLeftIcon } from '@chakra-ui/react'
import React from 'react'

import './ProjectBreadcrum.css'
import { changeAlpha } from './colorHelpers'
import { useNavigate } from 'react-router-dom'

function StepButton(
  text: string,
  color: string,
  icon: ComponentWithAs<'svg', IconProps>,
  isActive: boolean,
  canNavigateTo: boolean,
  navigateTo: (...params: string[]) => string,
  projectId: string,
  giftId?: string
): React.JSX.Element {
  const navigate = useNavigate()
  const toUrl = giftId ? navigateTo(projectId, giftId) : navigateTo(projectId)

  return (
    <Tag
      fontWeight='normal'
      bgColor={isActive ? color : changeAlpha(color, '0.06')}
      color={isActive ? 'var(--var-breadcrumb-stepButton-active-color)' : color}
      fill={isActive ? 'var(--var-breadcrumb-stepButton-active-color)' : undefined}
      stroke={isActive ? 'var(--var-breadcrumb-stepButton-active-color)' : undefined}
      className={isActive ? 'activeStep' : undefined}
      w='fit-content'
      h='29.5px'
      p='8px 12px'
      border='var(--var-breadcrumb-stepButton-border)'
      borderRadius='var(--var-breadcrumb-stepButton-border-radius)'
      opacity='50%'
      key={text}
      onClick={() => canNavigateTo && navigate(toUrl)}
      cursor={canNavigateTo ? 'pointer' : 'text'}
    >
      <TagLeftIcon boxSize='14px' as={icon} />
      {text}
    </Tag>
  )
}

export type Step = {
  name: string
  color: string
  icon: ComponentWithAs<'svg', IconProps>
  isActive: boolean
  canNavigateTo: boolean
  navigateTo: (...params: string[]) => string
}

export default function ProjectBreadcrumb({
  steps,
  projectId,
  giftId,
  ...other
}: {
  steps: Step[]
  projectId: string
  giftId?: string
} & BoxProps): React.JSX.Element {
  return (
    <HStack
      backdropFilter='var(--var-breadcrumb-backdrop-filter)'
      bgColor='var(--var-breadcrumb-bg)'
      mt='24px'
      gap='24px'
      className='stepsBar'
      {...other}
    >
      {steps.map(step =>
        StepButton(
          step.name,
          step.color,
          step.icon,
          step.isActive,
          step.canNavigateTo,
          step.navigateTo,
          projectId,
          giftId
        )
      )}
    </HStack>
  )
}
