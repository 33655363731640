import ProjectClient from '../domain/adapters/secondary/projectClient'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import Project from '../domain/project'

export default function useNameProjectUseCase(projectClient: ProjectClient) {
  const intl = useIntl()

  const [projectName, setProjectName] = useState('')
  const [isValidProjectName, setIsValidProjectName] = useState(true)
  const [isUniqueProjectName, setIsUniqueProjectName] = useState(true)
  const [createProjectError, setNameProjectError] = useState<string | null>(null)
  const [isProjectChanging, setIsProjectChanging] = useState(false)

  // we need a function for internal validation, as we cannot rely on state values
  // remember setState are dispatched on a taskScheduler for later execution,
  // execution is granted to have occurred only on next tick.
  const validateProjectName = useCallback(
    (projects: Project[], newName: string) => ({
      isUnique:
        projects.find(
          proj =>
            proj.name === newName &&
            (projectClient.currentProjectId === -1 || proj.id !== projectClient.currentProjectId)
        ) === undefined,
      isValidProjectName:
        newName.length >= 3 &&
        newName.length <= 50 &&
        newName.match(
          /^[a-zA-Z0-9 _\-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+$/
        ) !== null,
      isValid() {
        return this.isValidProjectName && this.isUnique
      }
    }),
    [projectClient.currentProjectId]
  )

  const createProject = useCallback(async () => {
    if (!validateProjectName(projectClient.projects, projectName).isValid()) {
      return
    }

    try {
      setIsProjectChanging(true)
      const newProject = await projectClient.createProject({
        name: projectName
      })
      if (newProject && newProject.id) {
        projectClient.projectSelected(newProject.id)
        setIsProjectChanging(false)
        setNameProjectError(null)
        return newProject.id
      } else {
        throw Error('new project is null')
      }
    } catch (err) {
      setNameProjectError(
        intl.formatMessage({
          description: 'createProjectErrorMessage',
          defaultMessage: "Une erreur s'est produite à la création du projet"
        })
      )
      setIsProjectChanging(false)
    }
  }, [projectClient, projectName, intl, validateProjectName])

  const renameProject = useCallback(
    async (projectId: number) => {
      if (!validateProjectName(projectClient.projects, projectName).isValid()) {
        return
      }
      try {
        setIsProjectChanging(true)
        await projectClient.renameProject(projectId, projectName)
        setIsProjectChanging(false)
      } catch (err) {
        setNameProjectError(
          intl.formatMessage({
            description: 'updateProjectErrorMessage',
            defaultMessage: "Une erreur s'est produite au renommage du projet"
          })
        )
        setIsProjectChanging(false)
      }
    },
    [intl, projectName, validateProjectName, projectClient]
  )

  const projectNameChanged = useCallback(
    async (name: string) => {
      setProjectName(name)
      const { isValidProjectName, isUnique } = validateProjectName(projectClient.projects, name)
      setIsValidProjectName(isValidProjectName)
      setIsUniqueProjectName(isUnique)
    },
    [projectClient.projects, validateProjectName]
  )

  return {
    projectName,
    isValidProjectName,
    isUniqueProjectName,
    isValid: isValidProjectName && isUniqueProjectName,
    isProjectChanging,
    createProjectError,
    createProject,
    projectNameChanged,
    renameProject
  }
}
