import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export default function DataContainer(props: {
  titleElem: string
  valueElem: React.JSX.Element
  color: string
  backgroundColor: string
}): React.JSX.Element {
  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      color={props.color}
      backgroundColor={props.backgroundColor}
      padding='16px 4px 24px 4px'
      gap='4px'
      borderRadius='8px'
      w='100%'
    >
      <Text fontFamily='DM Serif Text' fontSize='48px' fontWeight='400' lineHeight='48px'>
        {props.titleElem}
      </Text>
      <Text fontFamily='Nunito Sans' fontSize='10px' fontWeight='800' lineHeight='10px'>
        {props.valueElem}
      </Text>
    </Flex>
  )
}
