import React from 'react'
import { getSteps } from './breadcrumbStepsHelper'
import ProjectBreadcrumb from '../components/ProjectBreadcrumb'
import Header2 from '../components/Header2'
import { FormattedMessage } from 'react-intl'
import SmallText from '../components/SmallText'
import Page from '../components/Page'
import { Flex, FormControl, FormErrorMessage, Progress } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import SecondaryButton from '../components/SecondaryButton'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/PrimaryButton'

import './ConfigureLinkGift.css'
import { ConfigureGift } from '../components/ConfigureGift'
import { FileUpload } from '../components/FileUpload'

import './HideMainNavbar.css'
import useConfigurePdfGiftUseCase from '../useCases/configurePdfGiftUseCase'

export default function ConfigureLinkGift(): React.JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const {
    // title
    title,
    titleLoading,
    changeTitle,
    validateTitle,
    updateTitle,
    hasTitleChanged,
    // description
    description,
    descriptionLoading,
    changeDescription,
    validateDescription,
    updateDescription,
    hasDescriptionChanged,
    // fileUpload
    handlePdfUpload,
    handlePdfUploadError,
    pdfValidationError,
    validPdfMimeTypes,
    // picture
    pictureContent,
    handlePictureUpload,
    pictureLoading,
    pictureUploadError,
    // others
    updateError,
    loading,
    checkAllFields,
    existingPdfFileName,
    maxPdfWeightInMb,
    currentProjectId,
    currentStoreGift
  } = useConfigurePdfGiftUseCase()

  const steps = getSteps('Cadeaux', currentStoreGift?.reachedStep)

  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <Header2 alignSelf='center' mb='4px'>
        <FormattedMessage
          description='configurePdfGiftTitle'
          defaultMessage='Importez votre bon cadeau - PDF'
        />
      </Header2>
      <SmallText mb='24px'>
        <FormattedMessage
          description='configurePdfGiftSubtitle'
          defaultMessage='Sélectionnez le PDF depuis vos fichiers'
        />
      </SmallText>

      <FormControl isInvalid={pdfValidationError !== null} mb='40px' alignSelf='center' w='544px'>
        <FileUpload
          initialFilePath={existingPdfFileName ?? null}
          mode='pdf'
          onUploadError={handlePdfUploadError}
          onUpload={handlePdfUpload}
          maxWeightInMB={maxPdfWeightInMb * 1024}
          validMimeTypes={validPdfMimeTypes}
          chooseFileLabel={intl.formatMessage({
            description: 'fileUploadModalImportBtn',
            defaultMessage: 'Importer'
          })}
          fontWeight='600'
          color='var(--var-text-color)'
          bgColor='rgba(57, 119, 121, 0.04)'
          _hover={{
            borderRadius: '6px',
            border: '1.5px solid rgba(23, 68, 77, 0.50)',
            background: 'rgba(57, 119, 121, 0.04)'
          }}
          _focus={{
            borderRadius: '6px',
            border: '1.5px solid rgba(23, 68, 77, 0.50)',
            background: 'rgba(57, 119, 121, 0.04)',
            outline: '0px'
          }}
        />
        {pdfValidationError !== null ? (
          <FormErrorMessage mt='24px'>{pdfValidationError} </FormErrorMessage>
        ) : null}
      </FormControl>

      <ConfigureGift
        pTitle={title}
        onTitleChange={changeTitle}
        onTitleBlur={updateTitle}
        isValidTitle={!hasTitleChanged || validateTitle(title).success}
        titleError={hasTitleChanged ? validateTitle(title).error || undefined : undefined}
        description={description}
        onDescriptionChange={changeDescription}
        onDescriptionBlur={updateDescription}
        isValidDescription={!hasDescriptionChanged || validateDescription(description).success}
        descriptionError={
          hasDescriptionChanged ? validateDescription(description).error || undefined : undefined
        }
        pictureContent={pictureContent}
        isValidPicture={pictureUploadError === null}
        handlePictureUpload={handlePictureUpload}
        loading={titleLoading || descriptionLoading || pictureLoading}
      />

      {updateError !== null ? (
        <Flex w='554px' alignSelf='center' mb='16px' color='red.500'>
          {updateError}
        </Flex>
      ) : null}
      <Flex w='554px' alignSelf='center' mb='80px'>
        {loading ? <Progress isIndeterminate /> : null}
      </Flex>
      {currentStoreGift && (
        <Flex w='full' justifyContent='center' gap='24px' mt='48px' mb='24px'>
          <SecondaryButton
            as={DomLink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/creation`)
            }}
          >
            <FormattedMessage description='generalCancel' defaultMessage='Annuler' />
          </SecondaryButton>
          <PrimaryButton
            isDisabled={loading || !checkAllFields()}
            onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
              e.preventDefault()
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/receiversMode`)
            }}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      )}
    </Page>
  )
}
