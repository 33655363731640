import { StateCreator } from 'zustand'
import { ProjectStoreState } from '../../projectStore'
import CommandClient from '../../../domain/adapters/secondary/commandClient'
import { Command } from '../../../domain/command'
import { fetchApi } from '../../storeHelper'

export const createCommandSlice: StateCreator<
  ProjectStoreState,
  [['zustand/immer', never]],
  [],
  CommandClient
> = () => ({
  async getClientCommands(clientId: number): Promise<Command[]> {
    return await fetchApi<Command[]>(`clients/${clientId}/commands`, 'GET')
  },
  async reserveGift(projectId: number, giftId: number, commandId: number): Promise<Command> {
    return await fetchApi<Command>(
      `projects/${projectId}/gifts/${giftId}/commands/${commandId}`,
      'PUT',
      JSON.stringify({})
    )
  }
})
