import { FormattedMessage } from 'react-intl'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useProjectStore from '../stores/projectStore'

import './ExperienceFinalStep.css'

export default function FinalStep() {
  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  return (
    <main
      className={`container-5`}
      style={{
        backgroundImage:
          giftClient.openedGift?.template?.signatureBackgroundImage === undefined
            ? undefined
            : `url(/${giftClient.openedGift?.template?.signatureBackgroundImage}.png)`
      }}
    >
      <h1>
        <FormattedMessage
          description='finalStepTitle'
          defaultMessage="Merci et à bientot sur l'application Présent !"
        />
      </h1>

      <footer>
        <img src={'/present.svg'} alt={'Logo Présent'} />
      </footer>
    </main>
  )
}
