import useProjectStore from '../stores/projectStore'
import { useCallback, useState } from 'react'
import GiftClient from '../domain/adapters/secondary/giftClient'

export default function useChooseReceiverModeUseCase(giftClient: GiftClient) {
  const currentProjectId = useProjectStore(state => state.currentProjectId)
  const currentStoreGift = useProjectStore(
    state => state.projects.find(proj => proj.id === currentProjectId)?.gifts[0]
  )
  const updateGiftSendingMode = useProjectStore(state => state.updateGiftSendingMode)

  const [selectedMode, setSelectedMode] = useState(currentStoreGift?.event?.sendingMode)

  if (currentStoreGift?.id === undefined) {
    throw new Error('Missing gift')
  }

  const updateSelectedMode = useCallback(
    async (mode: 'UNIQUE' | 'MULTIPLE') => {
      if (currentStoreGift && currentStoreGift.id) {
        setSelectedMode(mode)
        await updateGiftSendingMode(currentProjectId, currentStoreGift.id, mode)
      }
    },
    [setSelectedMode, currentProjectId, currentStoreGift?.id, updateGiftSendingMode]
  )

  const initEvent = giftClient.initGiftEvent
  const initGiftEvent = useCallback(
    async (projectId: number, giftId: number) => await initEvent(projectId, giftId),
    [initEvent]
  )

  return {
    // mode selection
    selectedMode,
    updateSelectedMode,
    initGiftEvent,
    // other
    currentProjectId,
    currentStoreGift
  }
}
