import React from 'react'
import Page from '../components/Page'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Tag,
  TagCloseButton,
  TagLabel
} from '@chakra-ui/react'
import Header2 from '../components/Header2'
import { FormattedMessage, useIntl } from 'react-intl'
import SmallText from '../components/SmallText'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import { useNavigate, Link as Domlink } from 'react-router-dom'
import useProjectStore from '../stores/projectStore'
import { getSteps } from './breadcrumbStepsHelper'

import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useChooseOpeningGameAnswersUseCase from '../useCases/chooseOpeningGameAnswersUseCase'

export default function OpeningGameAnswersComponent(): React.JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const {
    currentStoreGift,
    currentProjectId,

    inputChanged,
    answersValid,
    answerInput,
    answers,

    answersChanged,
    answersValidChanged,

    isAnswersValid,
    updateOpeningGameAnswers
  } = useChooseOpeningGameAnswersUseCase(giftClient)

  const steps: Step[] = getSteps("Jeux d'ouverture", currentStoreGift?.reachedStep)

  if (currentStoreGift?.id === undefined) {
    throw new Error('Missing gift')
  }

  return (
    <Page gap='80px'>
      <ProjectBreadcrumb
        steps={steps}
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />

      <Flex flexDir='column' maxW='554px' w='100%' mx='auto' gap='80px'>
        <Flex flexDir='column' gap='24px'>
          <Flex flexDir='column' gap='4px'>
            <Header2 alignSelf='center' textAlign='center'>
              <FormattedMessage
                description='openingGameAnswersTitleLabel'
                defaultMessage='Configurer la réponse et les alternatives'
              />
            </Header2>
            <SmallText>
              <FormattedMessage
                description='openingGameAnswersSubtitle'
                defaultMessage='Saisissez une réponse simple et courte. Pour limiter les erreurs de frappe, vous pouvez saisir des réponses alternatives en les séparants avec une virgule.'
              />
            </SmallText>
          </Flex>
        </Flex>

        <FormControl isInvalid={!answersValid} display='flex' flexDir='column' gap='12px'>
          <Input
            size='lg'
            type='text'
            placeholder={intl.formatMessage({
              description: 'openingGameAnswersPlaceholder',
              defaultMessage: 'Saisissez la/les réponse(s) (séparées par « , »)'
            })}
            max={50}
            onChange={e => inputChanged(e.target.value)}
            value={answerInput}
          />
          <Flex flexWrap='wrap' gap='12px' display={answers.length === 0 ? 'disabled' : 'flex'}>
            {answers.map(answer => (
              <Tag key={answer}>
                <TagLabel>{answer}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    const localAnswers = answers.filter(a => a !== answer)
                    answersChanged(localAnswers)
                    answersValidChanged(isAnswersValid(localAnswers))
                  }}
                />
              </Tag>
            ))}
          </Flex>
          <FormErrorMessage>
            <FormattedMessage
              description='openingGameAnswersError'
              defaultMessage="Le nombre de réponses doit être compris entre 1 et 10, les réponses ne doivent pas contenir de caractères spéciaux à l'exception de « ? » et « ! » et doivent contenir entre 3 et 50 caractères."
            />
          </FormErrorMessage>
        </FormControl>

        <Flex justifyContent='center' gap='12px'>
          <SecondaryButton
            as={Domlink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/openingGame`)
            }}
          >
            <FormattedMessage description='generalReturn' defaultMessage='Retour' />
          </SecondaryButton>
          <PrimaryButton
            isDisabled={!answersValid || (answers.length === 0 && answerInput.trim() === '')}
            onClick={async e => {
              e.preventDefault()

              await updateOpeningGameAnswers()

              if (currentStoreGift?.openingMode === 'GAME') {
                navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/packaging`)
              } else if (currentStoreGift?.openingMode === 'GAME_AND_DATE') {
                navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/openingDate`)
              }
            }}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      </Flex>
    </Page>
  )
}
