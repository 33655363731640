/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import ExperiencePreLoadingScreen from './ExperiencePreLoadingScreen'
import Slide1 from '../components/ExperienceSlide1'
import Slide2 from '../components/ExperienceSlide2'
import Signature from '../components/ExperienceSignature'
import Gifts from '../components/ExperienceGifts'

import './ExperienceContainer.css'
import { Box } from '@chakra-ui/react'
import FinalStep from '../components/ExperienceFinalStep'

interface OpeningStep {
  step: string
  component: ((isFinished: () => void) => React.JSX.Element) | React.JSX.Element
  animationIn: string | undefined
  animationOut: string | undefined
}

export default function ExperienceContainer(): React.ReactElement {
  const [search, setSearch] = useSearchParams()
  const [openingStep, setOpeningStep] = useState(search.get('step'))
  const [currentStep, setCurrentStep] = useState(undefined as OpeningStep | undefined)
  const [animationToPlay, setAnimationToPlay] = useState(
    currentStep?.animationIn ?? ('fade-in' as string | undefined)
  )
  const nextRef = useRef<HTMLDivElement>(null)

  const possibleOpeningSteps: OpeningStep[] = [
    {
      step: 'preloading',
      component: <ExperiencePreLoadingScreen />,
      animationIn: 'fade-in',
      animationOut: 'swipe-up'
    },
    {
      step: 'slide1',
      component: <Slide1 />,
      animationIn: 'fade-in',
      animationOut: 'slide-out'
    },
    {
      step: 'slide2',
      component: <Slide2 />,
      animationIn: 'slide-in',
      animationOut: 'slide-out'
    },
    {
      step: 'signature',
      component: <Signature />,
      animationIn: 'slide-in',
      animationOut: 'slide-out'
    },
    {
      step: 'gifts',
      component: <Gifts nextRef={nextRef} />,
      animationIn: 'slide-in',
      animationOut: 'slide-out'
    },
    {
      step: 'final',
      component: <FinalStep />,
      animationIn: 'slide-in',
      animationOut: 'slide-out'
    }
  ]

  useEffect(() => {
    if (!possibleOpeningSteps.find(step => step.step === openingStep)) {
      console.warn('Invalid opening step in URL query params')
    }
    const stepName = search.get('step')
    setOpeningStep(stepName)
    setCurrentStep(possibleOpeningSteps.find(step => step.step === stepName))
  }, [search])

  function handleStepChange(e: React.MouseEvent, direction: 1 | -1): void {
    e.preventDefault()

    if (currentStep?.step === 'preloading') return

    const isNext = () => currentStep?.step === 'GAME' || currentStep?.step === 'DATE'
    const isPrev = () => currentStep?.step === 'slide1'

    if (direction === 1 && isNext()) return
    if (direction === -1 && isPrev()) return

    const currentIndex = possibleOpeningSteps.findIndex(step => step.step === openingStep)
    const newIndex = currentIndex + direction

    if (newIndex < 0) return
    if (newIndex >= possibleOpeningSteps.length) return
    if (!currentStep?.animationOut) return

    if (currentStep?.step === 'slide1') document.body.style.backgroundColor = '#fff'

    const animationSuffix = direction === -1 ? '-rev' : ''
    setAnimationToPlay(currentStep.animationOut + animationSuffix)
    setTimeout(() => {
      setAnimationToPlay(possibleOpeningSteps[newIndex].animationIn + animationSuffix)
      setSearch(`?step=${possibleOpeningSteps[newIndex].step}`)
    }, 400)
  }

  function onNextClick(e: React.MouseEvent): void {
    handleStepChange(e, 1)
  }

  function onPrevClick(e: React.MouseEvent): void {
    handleStepChange(e, -1)
  }

  function onFinished(): void {
    return
  }

  return (
    <main className={`experience-container ${animationToPlay}`}>
      <Box className={'experience-nav prev'} onClick={onPrevClick}></Box>
      {currentStep?.component instanceof Function
        ? currentStep.component(onFinished)
        : currentStep?.component}
      <Box ref={nextRef} className={'experience-nav next'} onClick={onNextClick}></Box>
    </main>
  )
}
