import { useCallback, useState } from 'react'
import { UploadFile, UploadProps } from 'antd'
import { type File, FileUploadProps } from '../FileUpload'
import { Kind, Receiver } from '../../domain/receiver'
import { useIntl } from 'react-intl'
import { RcFile } from 'antd/es/upload'
import Papa from 'papaparse'
import { useFileUploadReducer } from './useFileUploadReducer'
import Gift from '../../domain/gift'

export type CsvFileUploadProps = Omit<
  FileUploadProps,
  'mode' | 'initialFilePath' | 'onUpload' | 'onUploadError' | 'onCsvUpload' | 'onCsvFormatError'
> & {
  initialValue: string | null
  formatErrorMessage: string
  sizeErrorMessage: string
  unknownErrorMessage: string
  receiversChanged: (giftId: number, receivers: Receiver[]) => void
  setIsSuccess: (success: boolean) => void
  setIsLoading: (loading: boolean) => void
  giftFileNameById: Record<number, string>
  currentStoreGift: Gift | undefined
}

export function useCsvFileUpload(props: CsvFileUploadProps) {
  const intl = useIntl()

  if (Number.isNaN(props.maxWeightInMB)) {
    throw new Error('invalid maxWeightInMb (number expected)')
  }

  const [csvFileName, setCsvFileName] = useState(null as string | null)
  const [csvValidationError, setCsvValidationError] = useState(null as string | null)
  const [csvUploadError, setCsvUploadError] = useState(null as string | null)

  const handleCsvFileUpload = useCallback(
    async (file: UploadFile<File>) => {
      if (
        props.currentStoreGift &&
        props.currentStoreGift.id &&
        file.name === props.giftFileNameById[props.currentStoreGift.id]
      ) {
        return
      }
      setCsvFileName(file.name)
    },
    [props.currentStoreGift, props.giftFileNameById]
  )

  const {
    setIsSuccess,
    setIsLoading,
    receiversChanged,
    sizeErrorMessage,
    formatErrorMessage,
    unknownErrorMessage
  } = props

  const handleCsvUpload = useCallback(
    async (data: Receiver[]) => {
      setCsvValidationError(null)
      if (
        props.currentStoreGift &&
        props.currentStoreGift.id &&
        csvFileName === props.giftFileNameById[props.currentStoreGift.id]
      ) {
        return
      }
      try {
        if (props.currentStoreGift && props.currentStoreGift.id) {
          setIsLoading(true)
          receiversChanged(props.currentStoreGift?.id, data)
          setCsvUploadError(null)
          setIsSuccess(true)
        }
      } catch (err) {
        setCsvUploadError(
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {eventDesc}."
            },
            { eventDesc: 'la mise à jour du fichier csv' }
          )
        )
        setIsSuccess(false)
      } finally {
        setIsLoading(false)
      }
    },
    [
      intl,
      props.currentStoreGift,
      csvFileName,
      props.giftFileNameById,
      setIsLoading,
      setIsSuccess,
      receiversChanged
    ]
  )

  const handleCsvUploadError = useCallback(
    (err: string, fileName: string | null) => {
      const errorSelector: Record<string, string> = {
        'size error': sizeErrorMessage,
        'format error': formatErrorMessage
      }
      setCsvFileName(fileName)
      setCsvValidationError(null)
      setCsvUploadError(err in errorSelector ? errorSelector[err] : unknownErrorMessage)
      setIsSuccess(false)
    },
    [setIsSuccess, sizeErrorMessage, formatErrorMessage, unknownErrorMessage]
  )

  const handleCsvFormatError = useCallback(
    (formatError: string) => {
      setCsvValidationError(formatError)
      setCsvUploadError(null)
      setIsSuccess(false)
    },
    [setIsSuccess]
  )

  const uploadProps = Object.assign({}, props, {
    initialFilePath: null,
    onUpload: handleCsvFileUpload,
    onUploadError: handleCsvUploadError
  })
  const { state, dispatch, getBase64, handlePreview, beforeUpload } =
    useFileUploadReducer(uploadProps)

  const mapKind = function (s: string): Kind {
    if (s.toLocaleLowerCase() === 'homme') return 'Man'
    if (s.toLocaleLowerCase() === 'femme') return 'Woman'
    if (s.toLocaleLowerCase() === 'autre') return 'Other'

    throw new Error(`unknown kind: ${s}`)
  }

  const csvToReceiver = useCallback((data: string[][]) => {
    const headers = data[0].map(s => s.toLocaleLowerCase())
    const nameIndex = headers.indexOf('nom')
    const firstNameIndex = headers.indexOf('prénom')
    const kindIndex = headers.indexOf('genre')
    const emailIndex = headers.indexOf('email')

    return data.slice(1).map(row => {
      const receiver: Receiver = {
        name: row[nameIndex],
        firstname: row[firstNameIndex],
        kind: mapKind(row[kindIndex]),
        email: row[emailIndex]
      }
      return receiver
    })
  }, [])

  const validateCsv = (data: string[][]) => {
    const expectedColumns: string[] = ['nom', 'prénom', 'genre', 'email']
    const headersHaveExpectedLength = expectedColumns.length === data[0].length
    const headersHaveExpectedItemsInOrder = !expectedColumns
      .map((column, idx) => data[0][idx].toLocaleLowerCase() === column.toLocaleLowerCase())
      .includes(false)
    const allRowsHaveExpectedItemCount = !data
      .map(row => row.length === expectedColumns.length)
      .includes(false)

    return (
      headersHaveExpectedLength && headersHaveExpectedItemsInOrder && allRowsHaveExpectedItemCount
    )
  }

  const handleChange: UploadProps['onChange'] = useCallback(
    async ({ file }: { file: UploadFile<File> }) => {
      setCsvFileName(file.name)
      if (state.uploadError !== null) {
        handleCsvUploadError(state.uploadError, state.fileName)
        return
      }

      // Get this url from response in real world.
      const currFile = file.originFileObj || (file as RcFile)
      const content = await currFile.arrayBuffer()
      const decoder = new window.TextDecoder('utf-8')
      const blobContent = new Blob([await currFile.slice().arrayBuffer()], {
        type: file.type
      })
      const newFile: UploadFile<File> = {
        uid: file.uid,
        preview: decoder.decode(content),
        url: file.url,
        name: file.name,
        status: 'done',
        response: {
          uid: file.uid,
          url: file.url ?? '',
          name: file.name,
          preview: blobContent,
          previewUrl: await getBase64(blobContent),
          status: 'done'
        }
      }

      try {
        const result = await new Promise<Receiver[]>((resolve, reject) => {
          const fileToParse = new File([blobContent], file.name, {
            type: 'application/csv'
          })
          Papa.parse<string[]>(fileToParse, {
            complete: results => {
              if ((results.errors ?? []).length === 0) {
                if (!validateCsv(results.data)) {
                  reject(
                    new Error(
                      intl.formatMessage({
                        description: 'useCsvFileUploadInvalidContentFormatMsg',
                        defaultMessage:
                          'Le fichier ne correspond pas au modèle demandé, pour vous aider, veuillez télécharger le template csv en dessous'
                      })
                    )
                  )
                }
                try {
                  resolve(csvToReceiver(results.data))
                } catch (err) {
                  reject(err)
                }
                return
              }
              reject(results.errors)
            },
            error: (error: Error) => reject(error)
          })
        })
        dispatch({
          type: 'UPLOAD_FILE',
          payload: { file: newFile.response || null, error: null }
        })
        await handleCsvFileUpload(newFile)
        await handleCsvUpload(result)
      } catch (err) {
        if (err instanceof Error) {
          handleCsvFormatError(err.message)
          return
        }

        if (Array.isArray(err) && err.length > 0) {
          handleCsvFormatError(err.map(e => e.message).join(', '))
          return
        }
        handleCsvFormatError((err as Error)?.message ?? 'unknown error')
      }
    },
    [
      intl,
      state.uploadError,
      getBase64,
      dispatch,
      handleCsvFileUpload,
      handleCsvFormatError,
      handleCsvUpload,
      handleCsvUploadError,
      csvToReceiver,
      state.fileName
    ]
  )

  return {
    csvFileName,
    csvValidationError,
    csvUploadError,
    handleChange,
    handlePreview,
    beforeUpload,
    maxWeightInMB: props.maxWeightInMB,
    validMimeTypes: props.validMimeTypes,
    chooseFileLabel: props.chooseFileLabel,
    fileList: state.fileList
  }
}
