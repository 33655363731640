import { BoxProps, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import React, { useEffect, useRef, useState } from 'react'
import { UploadFile } from 'antd'
import { File, FileUpload } from './FileUpload'
import { ImageEditorDispatchContext, useImageEditorDispatch } from './contexts/imageEditorContext'

export type ImageUploadProps = BoxProps & {
  validMimeTypes: string[]
  maxWeightInMB: number
  onUpload: (file: UploadFile<File>) => void
}

export default function ImageUpload(props: ImageUploadProps): React.JSX.Element {
  const intl = useIntl()
  const [isInvalid, setIsInvalid] = useState(false)

  const [validationError, setValidationError] = useState(null as string | null)
  const uploadedFile = useRef(null as UploadFile<File> | null)

  const imageEditorDispatch = useImageEditorDispatch() as ImageEditorDispatchContext

  // on rerender, reinitializes state
  useEffect(
    function () {
      setValidationError(null)
      uploadedFile.current = null
      setIsInvalid(false)
    },
    [setValidationError, setIsInvalid]
  )

  const handleUploadError = (err: string) => {
    if (err.indexOf('size') >= 0) {
      setIsInvalid(() => true)
      imageEditorDispatch({ type: 'INVALID_UPLOAD' })
      setValidationError(
        intl.formatMessage(
          {
            description: 'fileUploadModalFileSizeErrorMsg',
            defaultMessage: 'Votre fichier ne peut pas excéder {maxSizeMo}mo'
          },
          { maxSizeMo: props.maxWeightInMB }
        )
      )
      return
    }

    if (err.indexOf('format') >= 0) {
      setIsInvalid(() => true)
      imageEditorDispatch({ type: 'INVALID_UPLOAD' })
      setValidationError(
        intl.formatMessage(
          {
            description: 'fileUploadModalFileFormatErrorMsg',
            defaultMessage: 'Votre fichier doit être aux formats: {validFormats}'
          },
          {
            validFormats: props.validMimeTypes
              .join(', ')
              .replaceAll('image/', '')
              .toLocaleUpperCase()
          }
        )
      )
      return
    }
    setIsInvalid(() => true)
    imageEditorDispatch({ type: 'INVALID_UPLOAD' })
    setValidationError(
      intl.formatMessage({
        description: 'fileUploadModalUnknownErrorMsg',
        defaultMessage: "Une erreur s'est produite."
      })
    )
  }

  const handleUpload = (file: UploadFile<File>) => {
    uploadedFile.current = file
    if (validationError !== null) {
      setValidationError(null)
      setIsInvalid(false)
      imageEditorDispatch({ type: 'VALID_UPLOAD' })
    }
    props.onUpload(uploadedFile.current)
  }

  return (
    <FormControl isInvalid={isInvalid} mb='40px'>
      <FileUpload
        initialFilePath={null}
        mode='picture'
        onUploadError={err => handleUploadError(err)}
        onUpload={file => handleUpload(file)}
        maxWeightInMB={props.maxWeightInMB * 1024}
        validMimeTypes={props.validMimeTypes}
        chooseFileLabel={intl.formatMessage({
          description: 'fileUploadModalImportBtn',
          defaultMessage: 'Importer'
        })}
        bgColor='rgba(57, 119, 121, 0.04)'
      />
      {isInvalid ? <FormErrorMessage mt='24px'>{validationError} </FormErrorMessage> : null}
    </FormControl>
  )
}
