import { Text } from '@chakra-ui/react'
import React from 'react'
import { TitleHeadingProps } from './TitleHeading'

export function ParagraphText({
  typedIndex,
  onClicked,
  text,
  ...boxProps
}: TitleHeadingProps<HTMLElement>): React.JSX.Element {
  return (
    <Text key={`text-${typedIndex}`} onClick={onClicked} {...boxProps}>
      {text}
    </Text>
  )
}
