import React from 'react'
import Page from '../components/Page'
import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import { Flex, Input } from '@chakra-ui/react'
import Header2 from '../components/Header2'
import { FormattedMessage, useIntl } from 'react-intl'
import SmallText from '../components/SmallText'
import SecondaryButton from '../components/SecondaryButton'
import PrimaryButton from '../components/PrimaryButton'
import { useNavigate, Link as Domlink } from 'react-router-dom'
import useProjectStore from '../stores/projectStore'
import { getSteps } from './breadcrumbStepsHelper'

import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useChooseOpeningGameUseCase from '../useCases/chooseOpeningGameUseCase'

export default function OpeningGameComponent(): React.JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const giftClient = useProjectStore(SlicesHelper.getGiftClient)
  const { question, questionChanged, currentProjectId, currentStoreGift, updateOpeningGame } =
    useChooseOpeningGameUseCase(giftClient)

  const steps: Step[] = getSteps("Jeux d'ouverture", currentStoreGift?.reachedStep)

  if (currentStoreGift?.id === undefined) {
    throw new Error('Missing gift')
  }

  return (
    <Page gap='80px'>
      <ProjectBreadcrumb
        steps={steps}
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />

      <Flex flexDir='column' maxW='554px' w='100%' mx='auto' gap='80px'>
        <Flex flexDir='column' gap='24px'>
          <Flex flexDir='column' gap='4px'>
            <Header2 alignSelf='center'>
              <FormattedMessage
                description='openingGameTitleLabel'
                defaultMessage='Configurer la question'
              />
            </Header2>
            <SmallText>
              <FormattedMessage
                description='openingGameSubtitle'
                defaultMessage='Votre destinataire devra répondre à cette question pour pouvoir ouvrir son présent. Pensez à terminer votre question par un point d’interrogation'
              />
            </SmallText>
          </Flex>
        </Flex>

        <Input
          size='lg'
          minLength={2}
          maxLength={50}
          type='text'
          placeholder={intl.formatMessage({
            description: 'openingGameQuestionPlaceholder',
            defaultMessage: 'Saisissez la question'
          })}
          onChange={e => {
            questionChanged(e.target.value)
          }}
          defaultValue={question}
        />

        <Flex justifyContent='center' gap='12px'>
          <SecondaryButton
            as={Domlink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift.id}/openingMode`)
            }}
          >
            <FormattedMessage description='generalReturn' defaultMessage='Retour' />
          </SecondaryButton>
          <PrimaryButton
            isDisabled={
              question === undefined ||
              question.length < 2 ||
              question.length > 50 ||
              question[question.length - 1] !== '?'
            }
            onClick={async e => {
              e.preventDefault()
              const success = await updateOpeningGame()
              if (!success) {
                return
              }
              navigate(
                `/projects/${currentProjectId}/gifts/${currentStoreGift?.id}/openingGame/answers`
              )
            }}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      </Flex>
    </Page>
  )
}
