import React, { useEffect, useRef, useState } from 'react'
import {
  Heading,
  HStack,
  VStack,
  ModalBody,
  ModalHeader,
  ModalProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import SecondaryButton from '../../components/SecondaryButton'
import PrimaryButton from '../../components/PrimaryButton'
import { FileUpload, File } from '../../components/FileUpload'
import { UploadFile } from 'antd/es'

export type FileUploadModalProps = Omit<ModalProps, 'children'> & {
  validMimeTypes: string[]
  maxWeightInMB: number
  onUpload: (content: UploadFile<File>) => void
}

export default function FileUploadModal(props: FileUploadModalProps): React.JSX.Element {
  const intl = useIntl()
  const [isInvalid, setIsInvalid] = useState(false)

  const [validationError, setValidationError] = useState(null as string | null)
  const uploadedFile = useRef(null as UploadFile<File> | null)

  // on rerender, reinitializes state
  useEffect(
    function () {
      setValidationError(null)
      uploadedFile.current = null
      setIsInvalid(false)
    },
    [props.isOpen]
  )

  const handleUploadError = (err: string) => {
    if (err.indexOf('size') >= 0) {
      setIsInvalid(() => true)
      setValidationError(
        intl.formatMessage(
          {
            description: 'fileUploadModalFileSizeErrorMsg',
            defaultMessage: 'Votre fichier ne peut pas excéder {maxSizeMo}mo'
          },
          { maxSizeMo: props.maxWeightInMB }
        )
      )
      return
    }

    if (err.indexOf('format') >= 0) {
      setIsInvalid(() => true)
      setValidationError(
        intl.formatMessage(
          {
            description: 'fileUploadModalFileFormatErrorMsg',
            defaultMessage: 'Votre fichier doit être aux formats: {validFormats}'
          },
          {
            validFormats: props.validMimeTypes
              .join(', ')
              .replaceAll('image/', '')
              .toLocaleUpperCase()
          }
        )
      )
      return
    }
    setIsInvalid(() => true)
    setValidationError(
      intl.formatMessage({
        description: 'fileUploadModalUnknownErrorMsg',
        defaultMessage: "Une erreur s'est produite."
      })
    )
  }

  const handleUpload = (file: UploadFile<File>) => {
    uploadedFile.current = file
    if (validationError !== null) {
      setValidationError(null)
      setIsInvalid(false)
    }
  }

  const validateUpload = () => {
    if (uploadedFile && uploadedFile.current) props.onUpload(uploadedFile.current)
    props.onClose()
  }

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size='md' textAlign='center'>
            <FormattedMessage
              description='fileUploadModalTitle'
              defaultMessage='Importez votre image'
            />
          </Heading>
        </ModalHeader>
        <ModalBody>
          <VStack>
            <FormLabel
              fontSize='md'
              lineHeight='24px'
              alignSelf='flex-start'
              fontWeight='400'
              fontFamily='Nunito Sans'
            >
              <FormattedMessage
                description='fileUploadModalAcceptedFormatsMsg'
                defaultMessage='Formats acceptés: {AcceptedFormats}'
                values={{
                  AcceptedFormats: props.validMimeTypes
                    .join(', ')
                    .replaceAll('image/', '')
                    .toLocaleUpperCase()
                }}
              />
            </FormLabel>
            <FormLabel
              fontSize='md'
              lineHeight='24px'
              alignSelf='flex-start'
              mb='12px'
              fontWeight='400'
              fontFamily='Nunito Sans'
            >
              <FormattedMessage
                description='fileUploadModalMaxSizeMsg'
                defaultMessage='Maximum: {MaxSize}mo'
                values={{ MaxSize: props.maxWeightInMB }}
              />
            </FormLabel>
            <FormControl isInvalid={isInvalid} mb='40px'>
              <FileUpload
                initialFilePath={null}
                mode='picture'
                onUploadError={err => handleUploadError(err)}
                onUpload={file => handleUpload(file)}
                maxWeightInMB={props.maxWeightInMB * 1024}
                validMimeTypes={props.validMimeTypes}
                chooseFileLabel={intl.formatMessage({
                  description: 'fileUploadModalImportBtn',
                  defaultMessage: 'Importer'
                })}
                bgColor='rgba(57, 119, 121, 0.04)'
              />
              {isInvalid ? <FormErrorMessage mt='24px'>{validationError} </FormErrorMessage> : null}
            </FormControl>
            <HStack>
              <SecondaryButton onClick={props.onClose}>
                <FormattedMessage description='generalCancel' defaultMessage='Annuler' />
              </SecondaryButton>
              <PrimaryButton isDisabled={isInvalid} onClick={() => validateUpload()}>
                <FormattedMessage description='generalConfirm' defaultMessage='Confirmer' />
              </PrimaryButton>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
