import React, { useState } from 'react'
import {
  Flex,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Text,
  Tag,
  Button,
  ButtonGroup
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import Project from '../domain/project'
import { Receiver } from '../domain/receiver'
import { GiftCompletedStep } from '../domain/gift'
import Icon from './Icon'
import PrimaryButton from './PrimaryButton'
import { Link as DomLink } from 'react-router-dom'

function Action(props: { project: Project }): React.JSX.Element {
  const [focus, setFocus] = useState(false)
  const navigate = useNavigate()

  return (
    <Flex gap='8px'>
      <Button
        backgroundColor='#39777914'
        _hover={{
          backgroundColor: '#39777914'
        }}
        color='#2E7B72'
        onClick={() => navigate(props.project.gifts[0].visualizationLink.substring(2))}
      >
        <Icon name='preview' color='#2E7B72' width='16px' height='16px' />
      </Button>
      <Button
        backgroundColor={focus ? '#17444D' : '#39777914'}
        _hover={{
          backgroundColor: focus ? '#17444D' : '#39777914'
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onClick={e => {
          e.preventDefault()
          navigate(`/project/creation${props.project ? '?id=' + props.project.id : ''}`)
        }}
      >
        <Icon name='settings' color='#2E7B72' width='16px' height='16px' />
      </Button>
    </Flex>
  )
}

function Status(props: { completedStep?: GiftCompletedStep }): React.JSX.Element {
  const inProgess = props.completedStep !== 'PACKAGING'

  return (
    <Tag
      size='lg'
      variant='solid'
      color={inProgess ? '#17444D' : '#fff'}
      backgroundColor={inProgess ? '#C5DEDC' : '#17444D'}
      fontFamily='Nunito Sans'
      fontSize='16px'
      fontWeight='400'
      lineHeight='20.8px'
    >
      {inProgess ? (
        <FormattedMessage description='homePageProjectStatusInProgress' defaultMessage='En cours' />
      ) : (
        <FormattedMessage
          description='homePageProjectStatusPackaging'
          defaultMessage='À emballer'
        />
      )}
    </Tag>
  )
}

function Receivers(props: { receivers?: Receiver[] }): React.JSX.Element {
  if (!props.receivers) return <>/</>
  return (
    <Text>
      {props.receivers[0]?.firstname}
      {props.receivers.length > 1 && (
        <>
          , +{props.receivers.length - 1}&nbsp;
          <FormattedMessage
            description='homePageProjectMultipleReceivers'
            defaultMessage='autres'
          />
        </>
      )}
    </Text>
  )
}

function ProjectRows(props: { projects: Project[]; qt: number }): React.JSX.Element {
  return (
    <>
      {props.projects
        .slice(Math.max(props.projects.length - props.qt, 0))
        .reverse()
        .map(project => (
          <Tr key={project.id}>
            <Td>{project.name}</Td>
            <Td>
              <Receivers receivers={project.gifts[0]?.event?.receivers} />
            </Td>
            <Td>
              {project.gifts[0]?.event?.date
                ? new Date(project.gifts[0]?.event?.date).toLocaleDateString()
                : '/'}
            </Td>
            <Td>
              <Status completedStep={project.gifts[0]?.reachedStep} />
            </Td>
            <Td>/</Td>
            <Td>
              <Action project={project} />
            </Td>
          </Tr>
        ))}
    </>
  )
}

export default function ProjectsTable(props: {
  projects: Project[]
  limit: number
}): React.JSX.Element {
  return (
    <Flex
      p={props.projects.length > 0 ? '0 12px 12px 12px' : '40px'}
      backgroundColor='#647D990A'
      borderRadius='12px'
      flexDirection='column'
    >
      {props.projects.length > 0 && (
        <Table variant='simple' className='home-table'>
          <Thead>
            <Tr>
              <Th>
                <FormattedMessage description='homePageProjectsTableName' defaultMessage='Nom' />
              </Th>
              <Th>
                <FormattedMessage
                  description='homePageProjectsTableReceiver'
                  defaultMessage='Destinataire'
                />
              </Th>
              <Th>
                <FormattedMessage
                  description='homePageProjectsTableDate'
                  defaultMessage="Date de l'évènement"
                />
              </Th>
              <Th>
                <FormattedMessage description='homePageProjectsTableStatus' defaultMessage='État' />
              </Th>
              <Th>
                <FormattedMessage
                  description='homePageProjectsTableOpening'
                  defaultMessage="Suivi d'ouverture"
                />
              </Th>
              <Th>
                <FormattedMessage
                  description='homePageProjectsTableActions'
                  defaultMessage='Actions'
                />
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            <ProjectRows projects={props.projects} qt={props.limit} />
          </Tbody>
        </Table>
      )}
      {props.projects.length <= 0 && (
        <Flex
          w='100%'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          gap='12px'
        >
          <Text
            color='Vous n’avez pas de projet en cous'
            fontFamily='DM Serif Display'
            fontSize='20px'
            fontWeight='400'
            lineHeight='25px'
          >
            <FormattedMessage
              description='homePageNoProject'
              defaultMessage='Vous n’avez pas de projet en cous'
            />
          </Text>
          <ButtonGroup>
            <PrimaryButton as={DomLink} to='/project/creation' size='lg'>
              <FormattedMessage
                description='homePageCreatePresentButton'
                defaultMessage='Créer un Présent'
              ></FormattedMessage>
            </PrimaryButton>
          </ButtonGroup>
        </Flex>
      )}
    </Flex>
  )
}
