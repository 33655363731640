import {
  As,
  BoxProps,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import React, { useRef, useState } from 'react'
import * as CSS from 'csstype'

export type InputWithValidationProps = Omit<BoxProps, 'onChange' | 'onBlur'> & {
  placeHolder: string
  maxLength?: number
  defaultValue: string
  isValid: boolean
  error?: string
  onChange: (value: string) => void
  onBlur: (value: string) => void
  index?: number
  onBlurEvenUnchangedOrInvalid?: (value: string) => void
  inputAs?: As
  autoHeight?: boolean
  after?: string
  containerBg?: CSS.Property.Color
  textWrap?: string
  showCheck?: boolean
  showPlaceholderOnFocus?: boolean
}

export default function InputWithValidation(props: InputWithValidationProps): React.JSX.Element {
  const inputRef = useRef(null as HTMLInputElement | null)
  const hasChanged = useRef(false)

  const [isInvalid, withoutError] = [!props.isValid, (props.error?.length ?? 0) === 0]

  const [showPlaceholderOnFocus, setShowPlaceholderOnFocus] = useState(
    props.showPlaceholderOnFocus ?? true
  )
  const [hasFocus, setHasFocus] = useState(false)

  if (isInvalid && withoutError) {
    throw new Error('invalid usage: when isValid is false you should pass an error message!')
  }

  const changeValue = () => {
    if (inputRef.current) {
      hasChanged.current = true
      props.onChange(inputRef.current.value)
    }
  }

  return (
    <FormControl
      w={props.width ?? '554px'}
      alignSelf='center'
      borderRadius={props.borderRadius ?? '6px'}
      mb='16px'
      isInvalid={!props.isValid}
      key={props.index}
      className={props.className}
      p={props.p}
      border={props.border}
      background={props.containerBg}
    >
      <InputGroup>
        <Input
          as={props.inputAs ?? 'input'}
          size='lg'
          h={props.autoHeight ?? false ? 'auto' : undefined}
          className={!props.isValid ? 'inactiveInput' : 'activeInput'}
          maxLength={props.maxLength}
          placeholder={((!hasFocus || showPlaceholderOnFocus) && props.placeHolder) || undefined}
          onChange={changeValue}
          onFocus={() => {
            props.showPlaceholderOnFocus ?? true
              ? setShowPlaceholderOnFocus(true)
              : setShowPlaceholderOnFocus(false)

            setHasFocus(true)
          }}
          onInput={props.onInput}
          onBlur={() => {
            setHasFocus(false)
            props.onBlurEvenUnchangedOrInvalid &&
              inputRef.current &&
              props.onBlurEvenUnchangedOrInvalid(inputRef.current.value)
            if (!hasChanged.current || !props.isValid || !inputRef.current) return
            props.onBlur(inputRef.current.value)
          }}
          ref={inputRef}
          value={props.defaultValue}
          resize={props.resize}
          background={props.background}
          appearance={props.appearance}
          fontFamily={props.fontFamily}
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}
          color={props.color}
          textAlign={props.textAlign}
          overflow={props.overflow}
          sx={{
            textWrap: 'balance'
          }}
        />
        {props.isValid && hasChanged.current && (props.showCheck ?? true) ? (
          <InputRightElement top='3px'>
            <CheckIcon color={'green.700'} />
          </InputRightElement>
        ) : null}
      </InputGroup>
      {!props.isValid ? <FormErrorMessage>{props.error}</FormErrorMessage> : null}
    </FormControl>
  )
}
