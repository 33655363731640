import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, BoxProps } from '@chakra-ui/react'
import { AlertStatus } from '@chakra-ui/alert'
import React from 'react'

export type PresentAlertProps = BoxProps & {
  status: AlertStatus
  title: string
  description: string
}
export default function PresentAlert(props: PresentAlertProps): React.JSX.Element {
  return (
    <Alert status={props.status}>
      <AlertIcon />
      <Box>
        <AlertTitle>{props.title}</AlertTitle>
        <AlertDescription>{props.description}</AlertDescription>
      </Box>
    </Alert>
  )
}
