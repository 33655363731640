import { Button, Flex, Link } from '@chakra-ui/react'
import { Link as DomLink, useLocation } from 'react-router-dom'

import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from './Icon'
import LogoutComponent from './LogoutComponent'

function LogoPresent() {
  return <Icon name={'present-nav'} color='#17444D' width='82px' height='24px' />
}

function NavLink(props: { path: string; iconName: string; children: React.JSX.Element }) {
  const location = useLocation()
  const isActive = location.pathname === props.path

  return (
    <Link
      display='flex'
      alignItems='center'
      gap='8px'
      p='16px 12px'
      bg={isActive ? '#3B858F1A' : 'transparent'}
      borderRadius='4px'
      color={isActive ? '#2E7B72' : '#17444D'}
      fontFamily='Nunito Sans'
      fontWeight={isActive ? '700' : '500'}
      fontSize='12px'
      lineHeight='24px'
      w='100%'
      as={DomLink}
      to={props.path}
      _hover={{ textDecoration: 'none' }}
    >
      <Icon
        name={props.iconName}
        color={isActive ? '#2E7B72' : '#17444D'}
        width='16px'
        height='16px'
      />
      {props.children}
    </Link>
  )
}

export default function Navbar(): React.JSX.Element {
  const location = useLocation()

  const isExperienceRoute = useMemo(
    () => /\/projects\/[0-9]+\/gifts\/[0-9]+\/steps\/[^/]+/.test(location.pathname),
    [location.pathname]
  )
  return (
    <Flex
      minW='216px'
      p='24px'
      gap='16px'
      alignItems='center'
      bg='#39777914'
      flexDirection='column'
      className={`main-navbar${isExperienceRoute ? ' experience' : ''}`}
    >
      <LogoPresent />
      <Flex flexDirection='column' w='100%'>
        <NavLink path='/home' iconName='home'>
          <FormattedMessage description='navabarLinkHome' defaultMessage='Accueil' />
        </NavLink>
        <NavLink path='/projects' iconName='projects'>
          <FormattedMessage description='navabarLinkProjects' defaultMessage='Projets cadeaux' />
        </NavLink>
        <NavLink path='/catalog' iconName='gift'>
          <FormattedMessage description='navabarLinkCatalog' defaultMessage='Catalogue cadeaux' />
        </NavLink>
        <NavLink path='/account' iconName='account'>
          <FormattedMessage description='navabarLinkAccount' defaultMessage='Mon compte' />
        </NavLink>
      </Flex>
      <Button colorScheme='red'>
        <LogoutComponent>Se déconnecter</LogoutComponent>
      </Button>
    </Flex>
  )
}
