import { StateCreator } from 'zustand'
import { ProjectStoreState } from '../../projectStore'
import LayoutClient from '../../../domain/adapters/secondary/layoutClient'
import { UploadLayoutPictureCommand } from '../../../models/commands/uploadLayoutPictureCommand'
import { fetchApi, fetchFormEncoded } from '../../storeHelper'
import { UpdateLayoutItemCommand } from '../../../models/commands/updateLayoutItemCommand'
import { Text } from '../../../domain/text'
import { Picture } from '../../../domain/picture'

export const createLayoutSlice: StateCreator<
  ProjectStoreState,
  [['zustand/immer', never]],
  [],
  LayoutClient
> = set => ({
  uploadLayoutPicture: async (command: UploadLayoutPictureCommand) => {
    await fetchFormEncoded<
      void,
      {
        picture: Blob
        fileName: string
      }
    >(
      `projects/${command.projectId}/gifts/${command.giftId}/layouts/${command.layoutId}/pictures/${command.pictureId}/upload`,
      'PUT',
      {
        picture: command.picture,
        fileName: `${command.pictureId}.png`
      }
    )
  },
  updateLayoutItem: async (command: UpdateLayoutItemCommand) => {
    const newItem = await fetchApi<Text | Picture>(
      `projects/${command.projectId}/gifts/${command.giftId}/layouts/${command.layoutId}/${
        command.itemType === 'TEXT' ? 'texts' : 'pictures'
      }/${command.layoutItemId}`,
      'PUT',
      JSON.stringify({ value: command.value })
    )
    set(state => {
      const projIdx = state.projects.findIndex(proj => proj.id === command.projectId)
      const giftIdx = state.projects[projIdx].gifts.findIndex(g => g.id === command.giftId)
      const layoutIdx = state?.projects[projIdx]?.gifts[giftIdx]?.template?.layouts.findIndex(
        layout => layout.id === command.layoutId
      )
      const template = state.projects[projIdx].gifts[giftIdx].template
      if (template) {
        if (command.itemType === 'TEXT' && layoutIdx) {
          const textIdx = state?.projects[projIdx]?.gifts[giftIdx]?.template?.layouts[
            layoutIdx
          ].texts.findIndex(t => t.id === command.layoutItemId)
          if (textIdx) {
            template.layouts[layoutIdx].texts[textIdx] = newItem as Text
          }

          return
        }

        if (layoutIdx) {
          const pictureIdx = template?.layouts[layoutIdx].pictures.findIndex(
            pict => pict.id === command.layoutItemId
          )
          template.layouts[layoutIdx].pictures[pictureIdx] = newItem as Picture
        }
      }
    })
  }
})
