import { z } from 'zod'
import { GiftInfos } from '../../stores/projectStore'
import { GiftInfosHelper } from '../../helpers/giftInfosHelper'
import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useGiftStoreDependencies } from './useGiftStoreDependencies'

export function useLink(initialValue: string) {
  const intl = useIntl()

  const { currentProjectId, currentStoreGift, updateGiftInfo } = useGiftStoreDependencies()

  const [link, setLink] = useState(initialValue)
  const [linkLoading, setLinkLoading] = useState(false)
  const [linkUpdateError, setLinkUpdateError] = useState(null as string | null)

  const hasLinkChanged = useRef(false)

  const validateLink = useCallback(
    function (link: string | number) {
      try {
        z.string().max(50).url().parse(link)
        return { success: true, error: null }
      } catch (err) {
        const error = intl.formatMessage({
          description: 'configureLinkGiftInvalidLinkMessage',
          defaultMessage: 'Ce champ ne contient pas de lien, veuillez entrer un lien fonctionnel.'
        })
        return { success: false, error }
      }
    },
    [intl]
  )

  const changeLink = useCallback(async (lnk: string) => {
    setLink(lnk)
    hasLinkChanged.current = true
  }, [])

  const updateLink = useCallback(
    async (lnk: string | null) => {
      if (lnk === null) {
        return
      }
      try {
        setLinkLoading(true)
        const currentGift: GiftInfos | undefined = currentStoreGift
          ? Object.assign(
              {},
              {
                picture: undefined,
                pdf: undefined,
                fileName: undefined,
                pdfFileName: undefined,
                pictureFileName: undefined
              },
              currentStoreGift
            )
          : undefined
        if (currentGift) {
          const updatedGift = GiftInfosHelper.updateProperty(currentGift, 'link', lnk)
          await updateGiftInfo(currentProjectId, updatedGift)
          setLinkUpdateError(null)
        } else {
          throw 'Unable to update gift info'
        }
      } catch (err) {
        setLinkUpdateError(
          intl.formatMessage(
            {
              description: 'configureLinkGiftUpdateError',
              defaultMessage: "Une erreur s'est produite lors de {eventDesc}."
            },
            { eventDesc: 'la mise à jour du lien' }
          )
        )
      } finally {
        setLinkLoading(false)
      }
    },
    [intl, currentProjectId, currentStoreGift, updateGiftInfo]
  )

  return {
    link,
    linkLoading,
    linkUpdateError,
    changeLink,
    updateLink,
    validateLink,
    hasLinkChanged: hasLinkChanged.current
  }
}
