import { BoxProps, Flex, FormLabel, Image } from '@chakra-ui/react'
import React from 'react'
import { UploadFile, UploadProps } from 'antd/es'
import { UploadFileStatus } from 'antd/es/upload/interface'
import Dragger from 'antd/es/upload/Dragger'
import { DownloadIcon } from './DownloadIcon'
import { CheckIcon, CloseIcon, Icon } from '@chakra-ui/icons'
import { useFileUploadReducer } from './hooks/useFileUploadReducer'

export type File = {
  uid: string
  name: string
  status: UploadFileStatus
  url: string
  preview: Blob
  previewUrl: string
}

export type FileUploadProps = BoxProps &
  UploadProps & {
    onUpload: (content: UploadFile<File>) => void
    onUploadError: (err: string) => void
    maxWeightInMB: number
    validMimeTypes: string[]
    chooseFileLabel: string
    mode: 'picture' | 'pdf'
    initialFilePath: string | null
  }
export function FileUpload(props: FileUploadProps): React.JSX.Element {
  const { state, handleChange, handlePreview, beforeUpload } = useFileUploadReducer(props)

  const hasFileName = (state.fileName?.length ?? 0) > 0
  const statusMark =
    state.uploadError === null && hasFileName ? (
      <CheckIcon color={'green.700'} />
    ) : state.uploadError !== null ? (
      <CloseIcon color='red.500' />
    ) : null

  const textColor =
    state.uploadError === null && hasFileName
      ? 'green.700'
      : state.uploadError !== null
      ? 'red.500'
      : undefined

  const pictureWithContent = (
    <Image src={state.fileContent || undefined} objectFit='contain' h='full' />
  )
  const importControl = (
    <Flex
      fontSize='20px'
      fontFamily='Nunito Sans'
      fontWeight='600'
      lineHeight='125%'
      color='#6AA5FD'
      padding='20px 32px'
      border='1px solid #6AA5FD'
      borderRadius='8px'
      backdropFilter='blur(15px)'
      gap='12px'
    >
      <FormLabel margin='0 auto' fontSize='lg'>
        {props.chooseFileLabel}
        <Icon as={DownloadIcon()} ml='12px' alignSelf='flex-end' />
      </FormLabel>
    </Flex>
  )

  const pdfWithContent = (
    <Flex
      padding='20px 32px'
      w='full'
      justifyContent='center'
      alignItems='center'
      gap='12px'
      alignSelf='stretch'
      borderRadius='8px'
      border={
        state.uploadError === null ? '1px solid #2E7B72' : '1px solid var(--chakra-colors-red-500)'
      }
      backdropFilter='blur(15px)'
      color={textColor}
    >
      {state.fileName}
      {statusMark}
    </Flex>
  )

  const content: Record<typeof props.mode, React.JSX.Element> = {
    picture: pictureWithContent,
    pdf: pdfWithContent
  }

  return (
    <Dragger
      listType='picture-card'
      fileList={state.fileList}
      beforeUpload={beforeUpload}
      onPreview={handlePreview}
      onChange={handleChange}
      showUploadList={false}
      maxCount={1}
      className={props.className}
      data-testid={'upload'}
    >
      {state.fileName !== null ? content[props.mode] : importControl}
    </Dragger>
  )
}
