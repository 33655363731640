import ProjectBreadcrumb, { Step } from '../components/ProjectBreadcrumb'
import { getSteps } from './breadcrumbStepsHelper'
import Header2 from '../components/Header2'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Flex, Grid, GridItem, Select, Text } from '@chakra-ui/react'
import LayoutsStack from '../components/LayoutsStack'
import useProjectStore from '../stores/projectStore'
import LayoutCard from '../components/LayoutCard'
import SecondaryButton from '../components/SecondaryButton'
import { Link as DomLink, useNavigate } from 'react-router-dom'
import PrimaryButton from '../components/PrimaryButton'
import Page from '../components/Page'

import './HideMainNavbar.css'
import SlicesHelper from '../stores/slices/helpers/slicesHelper'
import useApplyLayoutPackUseCase from '../useCases/applyLayoutPackUseCase'

export default function LayoutSelection(): React.JSX.Element {
  const navigate = useNavigate()

  const templateClient = useProjectStore(SlicesHelper.getTemplateClient)
  const projectClient = useProjectStore(SlicesHelper.getProjectClient)

  const {
    // layoutPack
    applyLayoutPackError,
    previewLayout,
    layouts,
    layoutPacks,

    applyLayoutPack,
    layoutPackChanged,
    isApplyLayoutsLoading,

    // navigation
    currentProjectId,
    currentStoreGift,
    currentTemplateId,
    currentTemplate
  } = useApplyLayoutPackUseCase(templateClient, projectClient)

  const steps: Step[] = getSteps('Édition', currentStoreGift?.reachedStep)
  return (
    <Page>
      <ProjectBreadcrumb
        steps={steps}
        pb='63.5px'
        justifyContent='center'
        projectId={currentProjectId.toString()}
        giftId={currentStoreGift?.id?.toString()}
      />
      <style>{`
          .layout-card { width: 320px; height: 420px; }
          .navBar { transform: translate(0, -500%); }
        `}</style>
      <Header2
        alignSelf='center'
        textAlign='center'
        my='auto'
        fontSize={[20, 22, 24, 38]}
        lineHeight='41px'
      >
        <FormattedMessage
          description='layoutSelectionTitleLabel'
          defaultMessage='Sélectionnez une mise en page'
        />
      </Header2>

      <Select
        alignSelf='center'
        w='220px'
        mt={'24px'}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => layoutPackChanged(e.target.value)}
        data-testid='selectLayout'
        defaultValue={currentStoreGift.layoutPack?.id ?? layoutPacks[0].id}
      >
        {layoutPacks.map(layoutPack => (
          <option key={layoutPack.id} value={layoutPack.id}>
            {layoutPack.name}
          </option>
        ))}
      </Select>

      <Grid
        templateColumns='repeat(2, min-content)'
        templateRows='repeat(2, auto)'
        rowGap='10px'
        columnGap='34px'
        margin={'0 auto'}
        transform={'scale(0.6) translate(0, -25%);'}
      >
        <GridItem
          textTransform='capitalize'
          backgroundColor='#1075ec'
          p='16px'
          borderRadius='12px'
          fontFamily='Nunito sans'
          fontSize='16px'
          fontWeight='600'
          lineHeight='130%'
          color='white'
          gridRow={1}
          gridColumn={1}
        >
          <FormattedMessage description='generalMessage' defaultMessage='message' />
        </GridItem>
        <GridItem gridRow={2} gridColumn={1}>
          <Flex className='layout'>
            <Text
              transform='rotate(-90deg)'
              alignSelf='flex-start'
              p='12px 8px 12px 8px'
              fontFamily='Nunito sans'
              fontSize='14px'
              textTransform={'capitalize'}
            >
              <FormattedMessage description='generalStart' defaultMessage='début' />
            </Text>
            <LayoutsStack
              gift={currentStoreGift}
              layouts={layouts}
              template={currentTemplate}
              sender={currentStoreGift.sender}
              excludedRole={'PREVIEW'}
              justifyContent='space-between'
              cardWidth='auto'
            />
          </Flex>
        </GridItem>
        {currentStoreGift.type !== 'MSG' && (
          <GridItem
            textTransform='capitalize'
            backgroundColor='#6310ec'
            p='16px'
            borderRadius='12px'
            fontFamily='Nunito sans'
            fontSize='16px'
            fontWeight='600'
            lineHeight='130%'
            color='white'
            gridRow={1}
            gridColumn={2}
          >
            <FormattedMessage description='generalGifts' defaultMessage='Cadeaux' />
          </GridItem>
        )}
        {currentStoreGift.type !== 'MSG' && (
          <GridItem gridRow={2} gridColumn={2}>
            <Flex className='layout'>
              <LayoutCard
                gift={currentStoreGift}
                template={currentTemplate}
                layout={previewLayout}
                sender={currentStoreGift.sender}
                gap='16px'
              />
              <Text
                textTransform='capitalize'
                p='8px'
                alignSelf='flex-start'
                transform='rotate(-90deg)'
                fontFamily='Nunito sans'
                fontSize='14px'
              >
                <FormattedMessage description='generalEnd' defaultMessage='fin' />
              </Text>
            </Flex>
          </GridItem>
        )}
      </Grid>

      {applyLayoutPackError && <Flex color='red.500'>{applyLayoutPackError}</Flex>}

      {currentStoreGift && (
        <Flex justifyContent='center' gap='24px' mt='80px' mb='24px' className={'navBar'}>
          <SecondaryButton
            as={DomLink}
            variant='outline'
            onClick={e => {
              e.preventDefault()
              navigate(`/projects/${currentProjectId}/gifts/${currentStoreGift}/template`)
            }}
          >
            <FormattedMessage description='generalReturn' defaultMessage='Retour' />
          </SecondaryButton>
          <PrimaryButton
            onClick={async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
              e.preventDefault()
              const success = await applyLayoutPack()
              if (!success) {
                return
              }
              navigate(
                `/projects/${currentProjectId}/gifts/${currentStoreGift.id}/template/${currentTemplateId}/layouts/editMessage`
              )
            }}
            isLoading={isApplyLayoutsLoading}
          >
            <FormattedMessage description='generalNext' defaultMessage='Suivant' />
          </PrimaryButton>
        </Flex>
      )}
    </Page>
  )
}
